import React, { useEffect, useState } from 'react';
import styled, {createGlobalStyle} from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import Button from '@material-ui/core/Button';
import HomeIcon from '@material-ui/icons/Home';
import CreateIcon from '@material-ui/icons/Create';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import SearchIcon from '@material-ui/icons/Search';
import HelpIcon from '@material-ui/icons/Help';
import MenuIcon from '@material-ui/icons/Menu';
import Collapse from '@material-ui/core/Collapse';
import LoopIcon from '@material-ui/icons/Loop';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import {
    resetRegisterIndividualStagesData, getContextRoot, getLanguageValue, navigateToPath,
    resetRegisterGroupStagesData, resetRetrieveIndividualData, resetRetrieveGroupData
} from "../common/functions";
import CONSTANTS from '../common/constants';
import MenuDropDown from './MenuDropDown';
import { ACTIONS } from "../redux/actions";
import { useLocation } from 'react-router-dom';
import CloseIcon from "@material-ui/icons/Close";
import lodashGet from 'lodash/get';
import ModalComponent from "./Modal";


function WarningBeforeStartingVisaComponent(props) {
    const [isModalOpened, setIsModalOpened ] = useState(true);

    const dispatch = useDispatch();

    if (!lodashGet(props, 'warningForIndividualConfig.content')) {
        return null;
    }

    return <>
        <ModalOverrideGlobalStyle />
        <ModalComponent
            isOpen={isModalOpened}
            modalClassName={'warning-before-starting-visa-modal'}
            showTitleCloseButton={true}
            onClose={(src) => { setIsModalOpened(false); clearStateData(); }}
            title={lodashGet(props, 'warningForIndividualConfig.title')}
            content={<ModalComponentStyle>

                <div className={'html-container'}>{lodashGet(props, 'warningForIndividualConfig.content')}</div>
                <div id="modal-buttons">
                    <Button className="start-individual-visa-button" onClick={() => {
                        setIsModalOpened(false);
                        setTimeout(() => {
                            resetRegisterIndividualStagesData(dispatch, props.state);
                            setTimeout(() => {
                                clearStateData();
                                setTimeout(() => {
                                    navigateToPath(dispatch, getContextRoot() + 'register-visa-for-individual');
                                }, 10);
                            }, 10);
                        }, 10);
                    }}>{getLanguageValue('top-menu.apply-for-visa.individual')}</Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button className="start-group-visa-button" onClick={() => {
                        setIsModalOpened(false);
                        setTimeout(() => {
                            resetRegisterGroupStagesData(dispatch, props.state);
                            setTimeout(() => {
                                clearStateData();
                                setTimeout(() => {
                                    navigateToPath(dispatch, getContextRoot() + 'register-visa-for-group');
                                }, 10);
                            }, 10);
                        }, 10);
                    }}>{getLanguageValue('top-menu.apply-for-visa.group')}</Button>
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <Button className="close-button" onClick={() => {
                        setIsModalOpened(false);
                        clearStateData();
                    }}>{getLanguageValue('need-help-section.modal-close')}</Button>
                </div>
            </ModalComponentStyle>}
        />
    </>;

    function clearStateData() {
        dispatch({
            type: ACTIONS.GENERIC_SET_VALUE,
            payload: [
                {
                    path: 'globalControls.openWarningForIndividual',
                    value: false
                },
                {
                    path: 'warningForIndividualConfig',
                    value: {
                        title: '',
                        content: ''
                    }
                }
            ]
        });
    }
}

const ModalComponentStyle = styled.div`
  #modal-buttons {
    background-color: lightgray;
    text-align: center;
    padding: 7px;
  }
  .start-individual-visa-button, .start-group-visa-button, .close-button {
    background-color: #fc3;
  }
  .modal-bottom-spacer {
    height: 20px;
  }
`;

const ModalOverrideGlobalStyle = createGlobalStyle`
  #modal-component.warning-before-starting-visa-modal {
    #modal-title {
      font-size: 33px;
      @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
        font-size: 24px;
      }
    }
    #modal-content {
      padding: 0 !important;
      font-size: 22px;
    }
    #modal-buttons {
      @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
        > button {
          display: block;
          width: calc(100% - 80px);
          margin: 0 auto;
        }
      }
    }
    .html-container {
      padding: 10px 10px;
    }
  }
`;

const WarningBeforeStartingVisa = connect(
    (state) => ({
        warningForIndividualConfig: state.warningForIndividualConfig
    }),
    {})(WarningBeforeStartingVisaComponent);

export default WarningBeforeStartingVisa;