import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {connect, useDispatch} from 'react-redux';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {
    getApiRoot,
    getContextRoot,
    getLanguageValue,
    navigateToPath,
    googleRecaptcha,
    getQueryParam,
    convertDateFieldsIntoString
} from "../common/functions";
import lodashGet from 'lodash/get';
import lodashSet from 'lodash/set';
import lodashCloneDeep from 'lodash/cloneDeep';
import CONSTANTS from '../common/constants';
import GenericFieldComponent from "./GenericFieldComponent";
import moment from 'moment';
import { ACTIONS } from '../redux/actions';
import Header from './Header';
import TopMenu from './TopMenu';
import MainPageAlerts from './MainPageAlerts';
import HeaderWithLogoBackground from "./HeaderWithLogoBackground";
import GlobalStyle from '../style/globalStyle';
import MainFooter from "./MainFooter";
import Loader from "./Loader";
import {
    useLocation
} from "react-router-dom";
import FloatingWhatsApp from "react-floating-whatsapp";

function RetrieveGroupApplicationComponent(props) {
    const dispatch = useDispatch();
    const location = useLocation();
    const contextRoot = getContextRoot();
    const {
        actions
    } = props;

    const [ autoInput, setAutoInput ] = useState(false);
    const [ loaders, setLoaders ] = useState({});
    const [ fieldsData, setFieldsData ] = useState({
        key: '',
        firstName: '',
        lastName: '',
        birthDate: '',
        email: '',
        ...props.retrieveGroupApplicationData
    });
    let [ fieldErrors, setFieldErrors ] = useState({});

    const FIELDS = [
        {
            key: 'key',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    disabled: autoInput,
                    className: 'field-component' + (fieldErrors.key ? ' field-error' : ''),
                    error: fieldErrors.key,
                    value: fieldsData.key,
                    inputProps: {
                        name: 'key'
                    },
                    helperText: fieldErrors.key || '',
                    onChange: DEFAULT_ON_CHANGE,
                    label: getLanguageValue('retrieve-group-information.group-id')
                }
            }
        },
        {
            key: 'lastName',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_STRING_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    disabled: autoInput,
                    className: 'field-component' + (fieldErrors.lastName ? ' field-error' : ''),
                    error: fieldErrors.lastName,
                    value: fieldsData.lastName,
                    inputProps: {
                        name: 'lastName'
                    },
                    helperText: fieldErrors.lastName || '',
                    onChange: DEFAULT_ON_CHANGE,
                    label: getLanguageValue('retrieve-group-information.family-name')
                }
            }
        },
        {
            key: 'firstName',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: DEFAULT_STRING_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    disabled: autoInput,
                    className: 'field-component' + (fieldErrors.firstName ? ' field-error' : ''),
                    error: fieldErrors.firstName,
                    value: fieldsData.firstName,
                    inputProps: {
                        name: 'firstName'
                    },
                    helperText: fieldErrors.firstName || '',
                    onChange: DEFAULT_ON_CHANGE,
                    label: getLanguageValue('retrieve-group-information.first-name')
                }
            }
        },
        {
            key: 'email',
            validators: [
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                },
                {
                    test: (value) => new RegExp(CONSTANTS.EMAIL_REGEX, "i").test(value),
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                }
            ],
            props: {
                type: 'textfield',
                fieldProps: {
                    disabled: autoInput,
                    className: 'field-component' + (fieldErrors.email ? ' field-error' : ''),
                    error: fieldErrors.email,
                    value: fieldsData.email,
                    inputProps: {
                        name: 'email'
                    },
                    helperText: fieldErrors.email || '',
                    onChange: DEFAULT_ON_CHANGE,
                    label: getLanguageValue('retrieve-group-information.email')
                }
            }
        },
        {
            key: 'birthDate',
            validators: [
                {
                    test: (value) => { value = document.querySelector('#birthDate').value; if (value && !moment(value, CONSTANTS.DATE_FORMAT_MOMENT, true).isValid()) return false; return true; },
                    errorMessage: getLanguageValue('register-field-errors-invalid')
                },
                {
                    test: DEFAULT_EMPTY_VALUE_VALIDATOR,
                    errorMessage: getLanguageValue('register-field-errors-empty')
                }
            ],
            props: {
                type: 'date',
                fieldProps: {
                    disabled: autoInput,
                    className: 'field-component' + (fieldErrors.birthDate ? ' field-error' : ''),
                    variant: 'inline',
                    format: CONSTANTS.DATE_FORMAT_DATEPICKER,
                    error: fieldErrors.birthDate,
                    autoOk: true,
                    value: fieldsData.birthDate || null,
                    inputProps: {
                        name: 'birthDate',
                        id: 'birthDate'
                    },
                    helperText: fieldErrors.birthDate || '',
                    onChange: DEFAULT_DATE_ON_CHANGE.bind({ fieldName: 'birthDate' }),
                    label: getLanguageValue('retrieve-group-information.date-of-birth')
                }
            }
        }
    ];

    useEffect(() => {
        //let searchString = window.location.search;
        if (getQueryParam('autoinputKey') && getQueryParam('autoinputFirstName') && getQueryParam('autoinputLastName') && getQueryParam('autoinputBirthDate') && getQueryParam('autoinputEmail')) {
            // try to start automatically
            setFieldsData({
                key: getQueryParam('autoinputKey'),
                firstName: decodeURIComponent(getQueryParam('autoinputFirstName')).replace(new RegExp('\\+', 'g'), ' '),
                lastName: decodeURIComponent(getQueryParam('autoinputLastName')).replace(new RegExp('\\+', 'g'), ' '),
                birthDate: moment(getQueryParam('autoinputBirthDate')).toDate(),
                email: getQueryParam('autoinputEmail')
            });
            setAutoInput(true);
        }
        window.routeChangeTimestamp = new Date().getTime();
    }, []);

    useEffect(() => {
        if (autoInput) {
            retrieve();
        }
    }, [ autoInput ]);

    function DEFAULT_EMPTY_VALUE_VALIDATOR(value) {
        if (!value) {
            return false;
        }
        return true;
    }

    function DEFAULT_STRING_VALUE_VALIDATOR(value) {
        if (!/^([\u0590-\u05FF]+)|(\s)*$/g.test(value)) {
            return false;
        }
        return true;
    }

    function DEFAULT_ON_CHANGE(e) {
        setFieldsData({...fieldsData, [e.target.name] : e.target.value });
        setFieldErrors({ ...fieldErrors, [e.target.name] : null });
    }

    function DEFAULT_DATE_ON_CHANGE(date) {
        if (moment(date, true).isValid()) {
            lodashSet(fieldsData, this.fieldName, date);
            setFieldsData({ ...fieldsData });
            setFieldErrors({ ...fieldErrors, [this.fieldName] : null });
        }
        else {
            lodashSet(fieldsData, this.fieldName, null);
            setFieldsData({...fieldsData });
        }
    }

    return <RetrieveGroupApplicationStyle>
        <GlobalStyle/>
        {CONSTANTS.WHATSAPP_ICON_ENABLED && <FloatingWhatsApp
            className={'floating-whatsapp'}
            phoneNumber={CONSTANTS.SUPPORT_PHONE_NUMBER}
            accountName={getLanguageValue('whatsapp.header-name')}
            statusMessage={getLanguageValue('whatsapp.status-message')}
            chatMessage={getLanguageValue('whatsapp.initial-message')}
        />}
        <Header className="header-component"/>
        <HeaderWithLogoBackground className="header-background-component" />
        <MainPageAlerts />
        <TopMenu className="top-menu-component" activeMenu={isContinueProcessPath() ? 'continue-visa-process' : 'check-visa-status'} disableAction={'retrieve-group-application' + (isContinueProcessPath() ? '-continue-process' : '')}/>
        <div className="content-body-container">
            <h1>{getLanguageValue('retrieve-group-information-title')}</h1>
            <div>{getLanguageValue('retrieve-group-information-explanation')}</div>
            <div className="bold-text field-warning-color">{getLanguageValue('retrieve-group-information-all-fields-required')}</div>
            <Grid container className={"fields-container"}>
                {FIELDS.map((fieldItem) => (<Grid key={fieldItem.key} item className={"single-field-container"} md={6}>
                    <GenericFieldComponent {...fieldItem.props} />
                </Grid>))}
            </Grid>
            <div className={"buttons-container"}>
                {!autoInput && <Button className="common-style-primary-button add-new-application-button" onClick={retrieve}>{getLanguageValue('retrieve-group-information.retrieve')} {loaders.retrieve && <Loader className={"loader-component"} />}</Button>}
                {autoInput && !fieldErrors.response && <Loader className={"autoinput-loader-component"} />}
            </div>
            {fieldErrors.response ? <div className={"error-response-container bold-text field-warning-color"} role={"alert"}>
                {fieldErrors.response}
            </div> : null}
        </div>
        <MainFooter className="footer-component" />
    </RetrieveGroupApplicationStyle>;

    async function retrieve() {
        if (loaders.retrieve) {
            return;
        }
        let duplicateData = lodashCloneDeep(fieldsData);
        convertDateFieldsIntoString(duplicateData, 'YYYY-MM-DD');
        let validateResult = validate({ setErrors: true });
        if (validateResult.length) {
            setTimeout(function() {
                if (document.querySelectorAll('.field-error')[0]) {
                    document.querySelectorAll('.field-error')[0].scrollIntoView();
                }
            }, 50);
            return;
        }
        let recaptchaToken = await googleRecaptcha();
        let options = {
            ...CONSTANTS.POST_DEFAULT_OPTIONS,
            body: JSON.stringify({ ...duplicateData, recaptchaToken })
        };
        try {
            setFieldErrors({ ...fieldErrors, response: null });
            setLoaders({ ...loaders, retrieve: true });
            const url = getApiRoot() + 'retrieve-information/?type=group&languageKey=' + props.languageKey + '&td=' + (new Date().getTime() - window.routeChangeTimestamp);
            let result = await (await fetch(url, options)).json();
            dispatch({
                type: ACTIONS.GENERIC_SET_VALUE,
                payload: [{
                    path: 'apiData.retrieve.group',
                    value: lodashGet(result, 'data')
                }]
            });
            if (!lodashGet(result, 'data.success')) {
                // error
                if (lodashGet(result, 'err') == 'mismatching field(s)') {
                    setFieldErrors({...fieldErrors, response: getLanguageValue('retrieve-group-information.retrieve-result-mismatching-fields') });
                }
                else {
                    setFieldErrors({...fieldErrors, response: getLanguageValue('retrieve-group-information.retrieve-result-no-such-group') });
                }
            }
            else {
                if (lodashGet(result, 'data.groupApplication.isExpired')) {
                    setFieldErrors({...fieldErrors, response: getLanguageValue('retrieve-individual-information.retrieve-result-expired') });
                    setLoaders({ ...loaders, retrieve: false });
                    return;
                }
                // organize data and save
                let stage1Data = { ...lodashGet(result, 'data.groupApplication') };
                stage1Data = {
                    firstName: stage1Data.firstName,
                    lastName: stage1Data.lastName,
                    organization: stage1Data.organization,
                    birthDate: moment(stage1Data.birthDate).toDate(),
                    email: stage1Data.email,
                    emailConfirm: stage1Data.email,
                    city: stage1Data.city,
                    addressLine: stage1Data.addressLine,
                    deliveryType: stage1Data.deliveryType,
                    deliveryDestination: stage1Data.deliveryDestination
                };
                let stage2Data = { linkedIndividualApplications: lodashGet(result, 'data.linkedIndividualApplications') };
                dispatch({
                    type: ACTIONS.GENERIC_SET_VALUE,
                    payload: [{
                        path: 'registerGroupStagesData',
                        value: {
                            isPaid: lodashGet(result, 'data.groupApplication.isPaid'),
                            createDate: lodashGet(result, 'data.groupApplication.createDate'),
                            key: lodashGet(result, 'data.groupApplication.key'),
                            status: lodashGet(result, 'data.groupApplication.status'),
                            stage1Data,
                            stage2Data,
                        }
                    }]
                });
                // go to page
                navigateToPath(dispatch, getContextRoot() + 'register-visa-for-group/' + (lodashGet(result, 'data.groupApplication.isPaid') ? 'status' : 'stage2'));
            }
        }
        catch (err) {
            // nothing to do
            setFieldErrors({...fieldErrors, response: getLanguageValue('retrieve-group-information.retrieve-result-network-error') });
        }
        setLoaders({ ...loaders, retrieve: false });
    }

    function validate(config) {
        let errors = [];
        if (lodashGet(config, 'setErrors')) {
            fieldErrors = {};
        }
        for (let fieldItem of FIELDS) {
            let fieldValue = fieldsData[fieldItem.key];
            let fieldValidators = fieldItem.validators;
            if (fieldValidators) {
                for (let fieldValidatorItem of fieldValidators) {
                    if (!fieldValidatorItem.test(fieldValue)) {
                        errors.push(fieldItem.key);
                        if (lodashGet(config, 'setErrors')) {
                            fieldErrors[fieldItem.key] = fieldValidatorItem.errorMessage;
                        }
                        break;
                    }
                }
            }
        }
        if (lodashGet(config, 'setErrors')) {
            setFieldErrors(fieldErrors);
        }
        return errors;
    }

    function isContinueProcessPath() {
        if (location.pathname.indexOf('retrieve-group-application/continue-process') > -1) {
            return true;
        }
        return false;
    }
}

const RetrieveGroupApplicationStyle = styled.div`
  .content-body-container {
    width: 80%;
    margin: 0 auto;
    padding-bottom: 25px;
  }
  .fields-container {
    margin-top: 25px;
  }
  .single-field-container {
    width: 100%;
    margin-bottom: 25px;
  }
  .field-component {
    width: calc(100% - 20px);
    html.rtl & {
      margin-left: 20px;
    }
    html.ltr & {
      margin-right: 20px;
    }
  }
  .error-response-container {
    margin-top: 10px;
  }
  .loader-component {
    color: white;
  }
`;

const RetrieveGroupApplication = connect(
    (state) => ({
        languageKey: state.languageKey, // make everything re-render
        retrieveGroupApplicationData: state.retrieveGroupApplication || {}
    }),
    {})(RetrieveGroupApplicationComponent);

export default RetrieveGroupApplication;