import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {connect, useDispatch} from 'react-redux';
import GlobalStyle from '../style/globalStyle';
import Header from './Header';
import TopMenu from './TopMenu';
import MainPageAlerts from './MainPageAlerts';
import lodashGet from 'lodash/get';
import { getContextRoot, getLanguageValue, googleRecaptcha, navigateToPath, resetRegisterGroupStagesData, resetRegisterIndividualStagesData, resetRetrieveGroupData, resetRetrieveIndividualData } from "../common/functions";
import MenuDropDown from './MenuDropDown';
import HowDoIApply from './HowDoIApply';
import NeedHelp from './NeedHelp';
import PersonIcon from '@material-ui/icons/Person';
import GroupIcon from '@material-ui/icons/Group';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import CONSTANTS from '../common/constants';
import { ZIndices } from '../common/zIndices';
import Fade from '@material-ui/core/Fade';
import MainFooter from "./MainFooter";
import HeaderWithLogoBackground from "./HeaderWithLogoBackground";
import NeedHelpFloatingComponent from './NeedHelpFloatingComponent';
import WhoCanGetAndHowToStart from "./WhoCanGetAndHowToStart";
import {ACTIONS} from "../redux/actions";
import FloatingWhatsApp from 'react-floating-whatsapp';
import NoNewVisasPopup from './NoNewVisasPopup';

function MainPageBodyComponent(props) {
    const dispatch = useDispatch();

    window.googleRecaptcha = googleRecaptcha;

    return <>
        <GlobalStyle/>
        <MainPageBodyStyle>
            {CONSTANTS.WHATSAPP_ICON_ENABLED && <FloatingWhatsApp
                className={'floating-whatsapp'}
                phoneNumber={CONSTANTS.SUPPORT_PHONE_NUMBER}
                accountName={getLanguageValue('whatsapp.header-name')}
                statusMessage={getLanguageValue('whatsapp.status-message')}
                chatMessage={getLanguageValue('whatsapp.initial-message')}
            />}
            <Header className="header-component" />
            <HeaderWithLogoBackground className="header-background-component" />
            <NeedHelpFloatingComponent />
            <MainPageAlerts />
            <TopMenu className="top-menu-component" activeMenu={'homepage'} disableAction={'homepage'}/>
            <div className="content-body-container">
                <div className="content-body-background-component"/>
                <div className="content-body-apply-for-visa-container">
                    <h1 className="title">{getLanguageValue('content-body.title')}</h1>
                    <div className="short-welcome"
                         dangerouslySetInnerHTML={{__html: getLanguageValue('content-body.short-welcome')}}/>
                    <div className="buttons-container">
                        <MenuDropDown
                            containerClassName={"content-menu-option first"}
                            buttonClassName={"content-menu-button"}
                            menuItemClassName={"content-menu-submenu"}
                            title={<>{getLanguageValue('content-body.buttons.apply-for-visa')}<ArrowDropDownIcon/></>}
                            titleClassName={'first'}
                            options={[
                                {
                                    display: <><PersonIcon className="menu-icon"/>{getLanguageValue('content-body.buttons.apply-for-visa.individual')}</>,
                                    action: function () {
                                        if (CONSTANTS.SYSTEM_DISABLED == 'yes') {
                                            dispatch({
                                                type: ACTIONS.GENERIC_SET_VALUE,
                                                payload: [{
                                                    path: 'globalControls.showNoNewVisasPopup',
                                                    value: true
                                                }]
                                            });
                                            return;
                                        }
                                        resetRegisterIndividualStagesData(dispatch, props.state);
                                        dispatch({
                                            type: ACTIONS.GENERIC_SET_VALUE,
                                            payload: [
                                                {
                                                    path: 'globalControls.openWarningForIndividual',
                                                    value: true
                                                },
                                                {
                                                    path: 'warningForIndividualConfig',
                                                    value: {
                                                        title: getLanguageValue('register-application-warning-before-starting-individual-visa-title'),
                                                        content: getLanguageValue('register-application-warning-before-starting-individual-visa-content')
                                                    }
                                                }
                                            ]
                                        });
                                    }
                                },
                                {
                                    display: <><GroupIcon className="menu-icon"/>{getLanguageValue('content-body.buttons.apply-for-visa.group')}</>,
                                    action: function () {
                                        if (CONSTANTS.SYSTEM_DISABLED == 'yes') {
                                            dispatch({
                                                type: ACTIONS.GENERIC_SET_VALUE,
                                                payload: [{
                                                    path: 'globalControls.showNoNewVisasPopup',
                                                    value: true
                                                }]
                                            });
                                            return;
                                        }
                                        resetRegisterGroupStagesData(dispatch, props.state);
                                        setTimeout(() => {
                                            navigateToPath(dispatch, getContextRoot() + 'register-visa-for-group');
                                        }, 10);
                                    }
                                }
                            ]}/>
                        <MenuDropDown
                            containerClassName={"content-menu-option second"}
                            buttonClassName={"content-menu-button"}
                            menuItemClassName={"content-menu-submenu"}
                            title={<>{getLanguageValue('content-body.buttons.continue-existing-visa-application')}<ArrowDropDownIcon/>
                            </>}
                            titleClassName={'second'}
                            options={[
                                {
                                    display: <><PersonIcon className="menu-icon"/>{getLanguageValue('content-body.buttons.continue-existing-visa-application.individual')}</>,
                                    action: function () {

                                        if (CONSTANTS.SYSTEM_DISABLED == 'yes') {
                                            dispatch({
                                                type: ACTIONS.GENERIC_SET_VALUE,
                                                payload: [{
                                                    path: 'globalControls.showNoNewVisasPopup',
                                                    value: true
                                                }]
                                            });
                                            return;
                                        }                                        resetRetrieveIndividualData(dispatch, props.state);
                                        setTimeout(() => {
                                            navigateToPath(dispatch, getContextRoot() + 'retrieve-individual-application');
                                        }, 10);
                                    }
                                },
                                {
                                    display: <><GroupIcon className="menu-icon"/>{getLanguageValue('content-body.buttons.continue-existing-visa-application.group')}</>,
                                    action: function () {
                                        if (CONSTANTS.SYSTEM_DISABLED == 'yes') {
                                            dispatch({
                                                type: ACTIONS.GENERIC_SET_VALUE,
                                                payload: [{
                                                    path: 'globalControls.showNoNewVisasPopup',
                                                    value: true
                                                }]
                                            });
                                            return;
                                        }
                                        resetRetrieveGroupData(dispatch, props.state);
                                        setTimeout(() => {
                                            navigateToPath(dispatch, getContextRoot() + 'retrieve-group-application');
                                        }, 10);
                                    }
                                }
                            ]}/>
                    </div>
                    <div className="how-to-start-container">
                        <a href="#" onClick={howToStart}>{getLanguageValue('content-body.how-to-start-help')}</a>
                    </div>
                </div>
            </div>
            <HowDoIApply className="how-to-apply-component" />
            <WhoCanGetAndHowToStart />
            <NeedHelp className="need-help-component" pageName={'MainPage'} />
            <MainFooter className="footer-component" />
            <NoNewVisasPopup />
        </MainPageBodyStyle>
    </>;

    function howToStart(e) {
        e.preventDefault();
        try {
            document.getElementById('need-help-component').scrollIntoView();
        }
        catch (err) {
            // nothing to do
        }
    }
}

const MainPageBodyStyle = styled.div`
  .content-body-container {
    height: 400px;
    position: relative;
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      height: unset;
    }
    .content-body-background-component {
      top: 0;
      position: absolute;
      background: url(${getContextRoot() + 'images/country_bg-2.jpg'}) no-repeat 100%;
      background-size: cover;
      width: calc(100%);
      height: 400px;
      @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
        height: 330px;
      }
    }
    .content-body-apply-for-visa-container {
        background: rgb(202,212,228);
        position: relative;
        width: 500px;
        top: 50px;
        padding: 25px;
        html.rtl & {
          left: -10%;
          transform-origin: left top 0;
        }
        html.ltr & {
          left: 20px;
        }
        @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
          width: calc(100% - 50px);
          left: unset !important;
          right: unset !important;
          top: unset !important;
          opacity: 0.9;
          height: 280px;
        }
        .title, .short-welcome {
          margin-bottom: 20px;
        }
        .title {
          font-size: 28px;
        }
        .content-menu-option {
          display: inline-block;
          font-size: 28px;
          width: 100%;
          border: 1px solid black;
          &.first {
            border-bottom: none;
            background-color: #045575;
            .MuiButton-label {
                color: white;
            }
          }
          &.second {
            background-color: white;
          }
          .MuiButton-label {
            font-weight: bold;
          }
        }
        .content-menu-button {
          width: 100%;
          height: 36px;
          vertical-align: top;
        }
        .how-to-start-container {
          margin-top: 5px;
          text-align: center;
          a {
            color: black !important;
          }
        }
    }
  }

`;

const MainPageBody = connect(
    (state) => ({
        state: state,
        languageKey: state.languageKey // make everything re-render
    }),
    {})(MainPageBodyComponent);

export default MainPageBody;
