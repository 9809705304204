
const ACTIONS = {
    SET_LANGUAGE: 'SET_LANGUAGE',
    GENERIC_SET_VALUE: 'GENERIC_SET_VALUE',
    ROUTER_SET_REDIRECT: 'ROUTER_SET_REDIRECT',
    ROUTER_UNSET_REDIRECT: 'ROUTER_UNSET_REDIRECT'
};

export {
    ACTIONS
};