import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import Header from './Header';
import TopMenu from './TopMenu';
import lodashGet from 'lodash/get';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import {getContextRoot, getLanguageValue} from "../common/functions";
import CONSTANTS from '../common/constants';
import { ACTIONS } from "../redux/actions";

function MainPageAlertsComponent(props) {
    const dispatch = useDispatch();
    const contextRoot = getContextRoot();
    const [ isVisible, setIsVisible ] = useState(true);

    const mainPageAlerts = getMainpageAlerts();

    if (props.mainPageAlertsHidden) {
        return null;
    }

    return <>
        <MainPageAlertsStyle>
            <div className="side-description-component">{getLanguageValue('announcements.side-description')}</div>
            <div className="announcements-container">
                {lodashGet(mainPageAlerts, 'length') > 0 && mainPageAlerts.map(function(alertItem, alertItemIndex) {
                    return <React.Fragment key={alertItemIndex}>
                        <InfoIcon className={"announcement-icon"} />
                        <span className="announcement-container" dangerouslySetInnerHTML={{ __html: alertItem }} />
                        <br/>
                    </React.Fragment>;
                })}

            </div>
            <Button className="close-button" onClick={() => {
                dispatch({
                    type: ACTIONS.GENERIC_SET_VALUE,
                    payload: [{ path: 'mainPageAlertsHidden', value: true }]
                });
            }}><CloseIcon className="close-icon" /></Button>

        </MainPageAlertsStyle>
    </>;

    function getMainpageAlerts() {
        let output = [];
        const alertsArray = getLanguageValue('mainpage-alerts-array').split(',');
        for (let loop = 0 ; loop < alertsArray.length ; ++loop) {
            const key = alertsArray[loop];
            const value = getLanguageValue('mainpage-alerts-' + key);
            output.push(value);
        }
        return output;
    }
}

const MainPageAlertsStyle = styled.div`
  display: flex;
  flex-wrap: wrap;
  color: white;
  position: relative;
  .side-description-component {
    background-color: #cc1f36;
    width: 25%;
    text-align: center;
    padding-top: 15px;
    padding-bottom: 15px;
    font-size: 22px;
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      display: none;
    }
  }
  .announcements-container {
    background-color: #8b0000;
    padding-right: 40px;
    width: calc(75% - 55px);
    padding-top: 15px;
    padding-bottom: 15px;
    padding-left: 15px;
    min-height: 120px;
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      width: 100%;
    }
  }
  .close-button {
    position: absolute;
    top: 0;
    right: 0;
    color: white;
  }
  
  .announcement-icon {
    color: white;
    vertical-align: middle;
    html.rtl & {
      padding-left: 3px;
    }
    html.ltr & {
      padding-right: 3px;
    }
  }
  .announcement-container {
    vertical-align: middle;
  }
`;

const MainPageAlerts = connect(
    (state) => ({
        mainPageAlertsHidden: state.mainPageAlertsHidden,
        languageKey: state.languageKey // make everything re-render
    }),
    {})(MainPageAlertsComponent);

export default MainPageAlerts;