import ReactDOM from 'react-dom';
import React, {useState} from 'react';
import styled from 'styled-components';
import Button from "@material-ui/core/Button";
import {getLanguageValue} from "../common/functions";
import {Document, Page} from "react-pdf/dist/umd/entry.webpack";
import Loader from "./Loader";

function PdfHandler(props) {
    const {
        base64Data,
        className,
        height = 500,
        controls // contains: onRenderSuccess, onError, sets: setPage
    } = props;

    const [ isLoading, setIsLoading ] = useState(true);
    const [ activePage, setActivePage ] = useState(1);
    const [ pages, setPages ] = useState([]);

    if (controls) {
        controls.setPage = function(pageValue) {
            if (pageValue > 0 && pageValue <= pages) {
                setActivePage(pageValue);
            }
        };
    }

    return <PdfHandlerStyle>
        <div className={'pdf-handler-pages-navigator-container ' + className}>
            {pages.map((page, pageIndex) => (<Button key={pageIndex} disabled={isLoading} className={`pdf-handler-page-button ${page == activePage ? 'active ' : ''}` + className} onClick={() => { if (activePage != page) { setIsLoading(true); setActivePage(page); } }}>{getLanguageValue('register-common.page')} #{page}</Button>))}
            {isLoading && <>&nbsp;&nbsp;<Loader /></>}
        </div>
        <Document
            className={'pdf-handler-document ' + className}
            file={base64Data}
            onLoadSuccess={(pdf) => { let localPages = []; for (let loop = 0 ; loop < pdf._pdfInfo.numPages; ++loop) { localPages.push(loop+1); } setPages(localPages); }}
            onLoadError={(error) => { if (controls?.onError) controls.onError(error); }}>
                <Page
                    className={'pdf-handler-page ' + className}
                    onRenderSuccess={() => { setIsLoading(false); if (controls?.onRenderSuccess) controls.onRenderSuccess(activePage); }}
                    renderTextLayer={false}
                    height={height}
                    pageNumber={activePage} />
        </Document>
    </PdfHandlerStyle>;
}

const PdfHandlerStyle = styled.div`
  .pdf-handler-page {
    @media (max-width: 900px) {
        max-width: 800px;
        overflow-x: scroll;
    }
    @media (max-width: 800px) {
        max-width: 544px;
        overflow-x: scroll;
    }
    @media (max-width: 650px) {
        max-width: 400px;
        overflow-x: scroll;
    }
    @media (max-width: 500px) {
        max-width: 310px;
        overflow-x: scroll;
    }
  }
  .pdf-handler-pages-navigator-container {
    margin-bottom: 10px;
  }
  .pdf-handler-pages-navigator-container .pdf-handler-page-button {
    border: 1px solid black;
    margin-right: 5px;
    &:disabled {
      color: black;
      opacity: 0.6;
    }
    &.active {
      background-color: #1c3764;
      color: white;
    }
  }
`;

export default PdfHandler;