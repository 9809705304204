import React, {useState} from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import {getContextRoot, getLanguageValue} from "../common/functions";
import lodashGet from 'lodash/get';
import ModalComponent from './Modal';
import { ACTIONS } from '../redux/actions';
import Button from '@material-ui/core/Button';

function NoNewVisasComponent(props) {
    const dispatch = useDispatch();
    const contextRoot = getContextRoot();

    return <>
            <ModalComponent
                disableBackdropClick={false}
                onClose={onClose}
                isOpen={props.showNoNewVisasPopup}
                showTitleCloseButton={false}
                title={getLanguageValue('no-new-visas.title')}
                content={<NoNewVisasStyle>{getLanguageValue('no-new-visas.content')}<br/><div className={"close-button-container"}><Button className={"close-button"} onClick={onClose}>{getLanguageValue('need-help-section.modal-close')}</Button></div></NoNewVisasStyle>}
            />

    </>;

    function onClose() {
        dispatch({
            type: ACTIONS.GENERIC_SET_VALUE,
            payload: [{
                path: 'globalControls.showNoNewVisasPopup',
                value: false
            }]
        });
    }
}

const NoNewVisasStyle = styled.div`
  .close-button-container {
    margin-top: 15px;
    text-align: center;
  }
  .close-button {
    background-color: #fc3;
  }
`;

const NoNewVisas = connect(
    (state) => ({
        showNoNewVisasPopup: lodashGet(state, 'globalControls.showNoNewVisasPopup'),
        languageKey: state.languageKey // make everything re-render
    }),
    {})(NoNewVisasComponent);

export default NoNewVisas;