function reportCustomerEmailVerification() {
    if (window.gtag) {
        window.gtag('event', 'conversion', {
            'send_to': 'AW-377523264/FdZHCKTj2JECEMCYgrQB',
            'event_callback': emptyCallback
        });
    }
}

function reportPurchase(price) {
    if (window.gtag) {
        window.gtag('event', 'conversion', {
            'send_to': 'AW-377523264/t74uCKH5s5ECEMCYgrQB',
            'value': price || 0,
            'currency': 'USD',
            'transaction_id': ''
        });
    }
}

function emptyCallback() { }


export {
    reportCustomerEmailVerification,
    reportPurchase
}