import React from 'react';
import styled from 'styled-components';
import Button from '@material-ui/core/Button';
import lodashGet from 'lodash/get';
import { getLanguageValue} from "../../common/functions";
import Loader from '../Loader';
import SaveIcon from '@material-ui/icons/Save';

function RegisterBottomButtons(props) {
    const {
        actions
    } = props;

    if (!actions || !actions.isNextDisabled || !actions.next) {
        return null;
    }

    return <RegisterBottomButtonsStyle className={"bottom-options-container"}>
        <Button className="general-style-next-button" disabled={actions.isNextDisabled()} onClick={actions.next}>{getLanguageValue('register-common.stages-buttons-next')} {lodashGet(actions, 'loaders.next') ? <>&nbsp; <Loader className="button-loader" /></> : ''}</Button>
        &nbsp;&nbsp;&nbsp;&nbsp;
        {actions.exit != null && <Button className="general-style-exit-button" onClick={actions.exit}>{getLanguageValue('register-common.stages-buttons-exit')} {lodashGet(actions, 'loaders.exit') ? <>&nbsp; <Loader className="button-loader" /></> : ''}</Button>}

        {actions.exit != null && actions.back != null && <>&nbsp;&nbsp;&nbsp;&nbsp;</>}

        {actions.back != null && <Button className="general-style-back-button" onClick={actions.back}>{getLanguageValue('register-common.stages-buttons-back')} {lodashGet(actions, 'loaders.back') ? <>&nbsp; <Loader className="button-loader" /></> : ''}</Button>}

        {(actions.back != null || actions.exit != null) && actions.saveAndExit != null && <>&nbsp;&nbsp;&nbsp;&nbsp;</>}

        {actions.saveAndExit != null && <Button className="general-style-save-and-exit-button" onClick={actions.saveAndExit}><SaveIcon /> {getLanguageValue('register-common.stages-buttons-save-and-exit')} {lodashGet(actions, 'loaders.saveAndExit') ? <>&nbsp; <Loader className="button-loader" /></> : ''}</Button>}

        {(actions.exit != null || actions.back != null || actions.saveAndExit != null) && actions.update != null && <>&nbsp;&nbsp;&nbsp;&nbsp;</>}

        {actions.update != null && <Button className="general-style-update-button" onClick={actions.update}><SaveIcon /> {getLanguageValue('register-common.stages-buttons-update')} {lodashGet(actions, 'loaders.update') ? <>&nbsp; <Loader className="button-loader" /></> : ''}</Button>}
    </RegisterBottomButtonsStyle>;

}

const RegisterBottomButtonsStyle = styled.div`
  padding-top: 30px;
  .button-loader {
    color: white;
  }
  html.ltr & {
    text-align: left;
    direction: rtl;
  }
`;

export default RegisterBottomButtons;