import React, { useState, useRef } from 'react';
import styled from 'styled-components';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import Popper from '@material-ui/core/Popper';
import Menu from '@material-ui/core/Menu';
import MenuList from '@material-ui/core/MenuList';
import Grow from '@material-ui/core/Grow';
import MenuItem from '@material-ui/core/MenuItem';
import CONSTANTS from '../common/constants';
import { ZIndices } from '../common/zIndices';

function MenuDropDown(props) {
    const {
        menuPlacement = 'bottom-start',
        menuItemClassName,
        buttonClassName,
        containerClassName,
        title,
        options
    } = props;

    const buttonRef = useRef();
    const [ isOpen, setIsOpen ] = useState(false);

    return <><MenuDropDownStyle className={containerClassName}><Button ref={buttonRef} onClick={() => { setIsOpen(true); }} className={buttonClassName || ''}>{title}</Button>
        <Popper open={isOpen} anchorEl={buttonRef.current} role={undefined} transition placement={menuPlacement} disablePortal style={{ zIndex: ZIndices.NAVIGATION_MENU }} className={"popper-component"}>
            {({ TransitionProps, placement }) => (
                <Grow {...TransitionProps} style={{ transformOrigin: placement === 'bottom' ? 'center top' : 'center bottom', zIndex: 1 }} className="grow-component">
                    <Paper className="paper-component">
                        <ClickAwayListener onClickAway={() => { setIsOpen(false); }}>
                            <MenuList autoFocusItem={isOpen} className={menuItemClassName || ''} onKeyDown={(e) => { if (e.key === 'Tab') { e.preventDefault(); setIsOpen(false); } }}>
                                {options.map(function(openItem, openItemIndex) {
                                    return <MenuItem key={openItemIndex} onClick={() => { setIsOpen(false); openItem.action(); }}>{openItem.display}</MenuItem>;
                                })}
                                </MenuList>
                        </ClickAwayListener>
                    </Paper>
                </Grow>
            )}
        </Popper>
    </MenuDropDownStyle>
    </>;

    // return <MenuDropDownStyle tabIndex={0} onKeyDown={(e) => { if (e.keyCode == 13) { setIsOpen(!isOpen); } }} onMouseOver={() => { if (toggleOnMouseEvents) setIsOpen(true); }} onMouseOut={() => { if (toggleOnMouseEvents) setIsOpen(false); }} className={"menu-drop-down-component " + (className || '')}>
    //     <ClickAwayListener onClickAway={() => { setIsOpen(false); }}>
    //         <span className={"menu-drop-down-title " + (titleClassName || '')} onClick={() => { setIsOpen(true); }}>{title}</span>
    //     </ClickAwayListener>
    //     <ul className={isOpen ? '' : 'drop-down-hidden'}>
    //         {options.map(function(optionItem, optionItemIndex) {
    //             return <li tabIndex={0} key={optionItemIndex} className={(optionItemIndex == 0 ? 'first' : '') + (optionItemIndex + 1== options.length ? ' last' : '')} onClick={() => { optionItem.action(); } }>{optionItem.display}</li>;
    //         })}
    //     </ul>
    // </MenuDropDownStyle>;
}

const MenuDropDownStyle = styled.div`
  display: inline-block;
  position: relative;
  .popper-component {
    width: 100%;
    @media (min-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE+1}px) {
      html.ltr & {
        min-width: 245px;
      }
    }
    z-index: ${ZIndices.NAVIGATION_MENU_DROPDOWN} !important;
  }
`;

export default MenuDropDown;