import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {connect, useDispatch} from 'react-redux';
import {
    getContextRoot,
    getApiRoot,
    getLanguageValue,
    getQueryParam,
    navigateToPath,
    googleRecaptcha,
    changeLanguage,
    getFileType,
    genericGetListFromLanguage,
    splitArrayIntoChunks, isInactiveField,
    getExtensionByFileContent
} from "../../common/functions";
import { useParams } from 'react-router-dom';
import lodashGet from 'lodash/get';
import CONSTANTS from '../../common/constants';
import Loader from "../Loader";
import Button from '@material-ui/core/Button';
import { CSS_COLORS } from '../../common/cssColors';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import CheckIcon from '@material-ui/icons/Check';
import { DropzoneAreaBase } from 'material-ui-dropzone';
import Modal from '@material-ui/core/Modal';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import CropHandler from '../CropHandler';
import lodashSet from "lodash/set";
import PdfHandler from "../PdfHandler";



function AgentManagerPageBodyComponent(props) {
    const dispatch = useDispatch();
    const contextRoot = getContextRoot();
    // modal vars

    const isMobile = useMediaQuery(`(max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px)`);
    const [ isModalOpen, setIsModalOpen ] = useState(false);
    const [ apiResult, setApiResult ] = useState(null);
    const [ selectedPersonToView, setSelectedPersonToView ] = useState(null);
    const [ loaders, setLoaders ] = useState({});
    const [ isLoading, setIsLoading ] = useState(false);
    let [ modifiedUserFileObjects, setModifiedUserFileObjects ] = useState({});
    let [ fileObjects, setFileObjects ] = useState([]);
    let [ cropHandlerControls , setCropHandlerControls ] = useState({
        passport: {},
        passportFace: {},
        personalIdWithAppendix: {},
        healthGreenCardOrVaccination: {},
        birthCertificate: {},
        parentPassport: {},
        passportBackside: {}
    });
    cropHandlerControls.passport.callback = cropHandlerCallback.bind({ fileType: 'passport' });
    cropHandlerControls.passportFace.callback = cropHandlerCallback.bind({ fileType: 'passportFace' });
    cropHandlerControls.personalIdWithAppendix.callback = cropHandlerCallback.bind({ fileType: 'personalIdWithAppendix' });
    cropHandlerControls.healthGreenCardOrVaccination.callback = cropHandlerCallback.bind({ fileType: 'healthGreenCardOrVaccination' });
    cropHandlerControls.birthCertificate.callback = cropHandlerCallback.bind({ fileType: 'birthCertificate' });
    cropHandlerControls.parentPassport.callback = cropHandlerCallback.bind({ fileType: 'parentPassport' });
    cropHandlerControls.passportBackside.callback = cropHandlerCallback.bind({ fileType: 'passportBackside' });

    let imagesList = [
        {
            key: 'passport',
            fieldId: 'passport-upload-field',
            displayText: 'Passport'
        },
        {
            key: 'passportFace',
            fieldId: 'passport-face-upload-field',
            displayText: 'Passport Face'
        },
        {
            key: 'birthCertificate',
            fieldId: 'birth-certificate-upload-field',
            displayText: 'Birth Certificate (under 18)'
        },
        {
            key: 'parentPassport',
            fieldId: 'parent-passport-upload-field',
            displayText: 'Parent Passport (under 18)'
        },
        {
            key: 'personalIdWithAppendix',
            fieldId: 'personal-id-with-appendix-upload-field',
            displayText: 'Parent Id with appendix'
        },
        {
            key: 'healthGreenCardOrVaccination',
            fieldId: 'health-green-card-or-vaccination-upload-field',
            displayText: 'Health Green Card or Vaccination'
        },
        {
            key: 'passportBackside',
            fieldId: 'passport-backside-upload-field',
            displayText: 'Passport backside (country of citizenship is India)'
        }
    ];

    let stage2Fields = [
        { key: 'firstNameEnglish', description: 'First Name', render: function(value) { return value; } },
        { key: 'lastNameEnglish', description: 'Last Name', render: function(value) { return value; } },
        { key: 'motherName', description: 'Mother Name', render: function(value) { return value; } },
        { key: 'fatherName', description: 'Father Name', render: function(value) { return value; } },
        { key: 'gender', description: 'Gender', render: function(value) { return getLanguageValue('register-individual.stage2-gender-' + value); } },
        { key: 'birthDate', description: 'Birth Date', render: function(value) { return moment(value).format(CONSTANTS.DATE_FORMAT_MOMENT); } },
        { key: 'cityOfBirth', description: 'City Of Birth', render: function(value) { return value; } },
        { key: 'countryOfBirth', description: 'Country Of Birth', render: function(value) { return value; } },
        { key: 'passportNumber', description: 'Passport Number', render: function(value) { return value; } },
        { key: 'countryOfCitizenship', description: 'Country Of Citizenship', render: function(value) { return value } },
        { key: 'issuanceDate', description: 'Issuance Date', render: function(value) { return moment(value).format(CONSTANTS.DATE_FORMAT_MOMENT); } },
        { key: 'expirationDate', description: 'Expiration Date', render: function(value) { return moment(value).format(CONSTANTS.DATE_FORMAT_MOMENT); } },
        { key: 'issuingCountry', description: 'Issuing Country', render: function(value) { return value; } },
        { key: 'issuingCity', description: 'Issuing City', render: function(value) { return value; } },
        { key: 'personalIdentificationNumber', description: 'Personal Identification Number', render: function(value) { return value; } },
        { key: 'flightCountryOrigin', description: 'Flight Country Origin', render: function(value) { return value; } },
        { key: 'purposeOfTravel', description: 'Purpose of travel', render: function(value) { return value; } },
        { key: 'borderOfEntry', description: 'Border of entry', render: function(value) { return value; } },
    ];
    let stage2Chunks = splitArrayIntoChunks(stage2Fields, 4);

    let stage3Fields = [
        { key: 'addressLine1', description: 'Address Line 1', render: function(value) { return value; } },
        { key: 'addressLine2', description: 'Address Line 2', render: function(value) { return value; } },
        { key: 'apartmentNumber', description: 'Apartment Number', render: function(value) { return value; } },
        { key: 'city', description: 'City', render: function(value) { return value; } },
        { key: 'stateOrProvince', description: 'State or Province', render: function(value) { return value; } },
        { key: 'Country', description: 'Country', render: function(value) { return value; } },
        { key: 'maritalStatus', description: 'Marital status', render: function(value) { return value; } },
        { key: 'education', description: 'Education', render: function(value) { return value; } },
        { key: 'profession', description: 'Profession', render: function(value) { return value; } }
    ];
    const rejectReasonsImageFields = [
        { key: 'passport', label: 'Passport', isDisplayed: true },
        { key: 'passportFace', label: 'Passport Face', isDisplayed: true },
        { key: 'birthCertificate', label: 'Birth Certificate', isDisplayed: selectedPersonToView?.files?.filter((fileItem, fileItemIndex) => fileItem.fileType == 'birthCertificate')?.length > 0 },
        { key: 'parentPassport', label: 'Parent Passport', isDisplayed: selectedPersonToView?.files?.filter((fileItem, fileItemIndex) => fileItem.fileType == 'parentPassport')?.length > 0 }
    ];
    const rejectReasonsInputFields = [
        { key: 'stage2FirstNameEnglish', label: 'First Name (English)', isDisplayed: true },
        { key: 'stage2LastNameEnglish', label: 'Last Name (English)', isDisplayed: true },
        { key: 'stage2BirthDate', label: 'Birth Date', isDisplayed: true },
        { key: 'stage2PassportNumber', label: 'Passport Number', isDisplayed: true },
        { key: 'stage2CountryOfBirth', label: 'Country of birth', isDisplayed: true },
        { key: 'stage2ExpirationDate', label: 'Expiry Date', isDisplayed: true },
        { key: 'stage2IssuanceDate', label: 'Issue Date', isDisplayed: true }
    ];
    let stage3Chunks = splitArrayIntoChunks(stage3Fields, 4);

    const [ fileObjectsToDelete, setFileObjectsToDelete ] = useState([]);
    const [ errors, setErrors ] = useState({ });
    const flightCompanies = getLanguageValue('register-individual.stage4-flight-companies-array').split(',').map((value) => ({ value, displayText: getLanguageValue('register-individual.stage4-flight-company-' + value) }));
    const selectedFlightCompanyDisplayText = lodashGet(flightCompanies.find((item) => item.value == lodashGet(selectedPersonToView, 'stage4Data.flightCompany')), 'displayText');
    const [ rejectReasons, setRejectReasons ] = useState({ });
    const [ showRejectWarning, setShowRejectWarning ] = useState(false);

    const isDebugging = getQueryParam('debug');

    const routerProps = useParams();

    useEffect(() => {
        document.title = 'Agent Manager';
        changeLanguage('he', async function() {
            try {

                let url = getApiRoot() + 'agent-api/agent-retrieve-information/';
                let recaptchaToken = await googleRecaptcha();
                let options = {
                    ...CONSTANTS.POST_DEFAULT_OPTIONS,
                    body: JSON.stringify({
                        recaptchaToken,
                        key: routerProps.id
                    })
                };
                let result = await (await fetch(url, options)).json();
                // add pdf mark
                if (lodashGet(result, 'data.success')) {
                    if (lodashGet(result, 'data.type') == 'individual') {
                        (lodashGet(result, 'data.individualApplication.files') || []).forEach(fileItem => {
                            if (fileItem.fileExtension == 'pdf') {
                                fileItem.showAsPdf = true;
                            }
                        });
                        selectPersonFromGroup(lodashGet(result, 'data.individualApplication'));
                        if (lodashGet(result, 'data.individualApplication.status') == 'reject' && lodashGet(result, 'data.individualApplication.rejectReasons')) {
                            setRejectReasons(lodashGet(result, 'data.individualApplication.rejectReasons'));
                        }
                    }
                    else {
                        (lodashGet(result, 'data.linkedIndividualApplications') || []).forEach(applicationItem => {
                            (lodashGet(applicationItem, 'files') || []).forEach(fileItem => {
                                if (fileItem.fileExtension == 'pdf') {
                                    fileItem.showAsPdf = true;
                                }
                            });
                        })
                    }
                    setApiResult(result);
                }
                else {
                    setApiResult({ err: 'general error' });
                }
            }
            catch (err) {
                // nothing to do
                setApiResult({ err: 'general error' });
            }
        });
        (async function() {
            let result = await (await fetch('/lang/lang.he.js')).text();
            window.LANGUAGE_BAK = window.LANGUAGE;
            eval(result);
            let backupValues = {};
            for (let key in window.LANGUAGE) {
                if (key.startsWith('register-individual.stage2-select-visa-type-') || key.startsWith('register-individual.stage2-select-visa-delivery-type-')) {
                    backupValues[key] = window.LANGUAGE[key];
                }
            }
            window.LANGUAGE = window.LANGUAGE_BAK;
            for (let key in backupValues) {
                window.LANGUAGE[key] = backupValues[key];
            }
            delete window.LANGUAGE_BAK;
        })();
    }, []);

    if (!routerProps.id) {
        navigateToPath(dispatch, getContextRoot());
        return;
    }

    return <>
        <AgentManagerStyle>
            <img className={"logo-image"} src={getContextRoot() + 'images/logo.png'} />
            <br/>
            <h1>VisaDaba Agent Manager</h1>
            {!apiResult ? <>Loading...<Loader /></> : null }
            {apiResult && apiResult.err ? <><div className="general-error-message">Failed to load data. Please cotact support with (1) your link, (2) current time.</div></> : null }
            { apiResult && apiResult.data ? <>
            <h2>{lodashGet(apiResult, 'data.type') == 'group' ? 'Group Application Information (' +  lodashGet(apiResult, 'data.groupApplication.key') + ')' : 'Individual Application Information (' + lodashGet(apiResult, 'data.individualApplication.key') + ')'}</h2>

            <div className={"group-view-container"}>
                {lodashGet(apiResult, 'data.type') == 'group' && <div className={"group-short-instructions-container"}>
                    <div className={"group-short-instructions-inner-container"}>
                        1){ selectedPersonToView ? <span className={"checked-icon-container"}><CheckIcon/></span> : null} Select A Person
                        <br/>
                        2){isGroupReady() || isIndividualPassedStage(2) ? <span className={"checked-icon-container"}><CheckIcon/></span> : null} Click "start procedure"
                        <br/>
                        3) {isGroupReady() || isUploadedVisa() ? <span className={"checked-icon-container"}><CheckIcon/></span> : null} Upload visa files in the end
                        <br/>
                        4) {isGroupReady() || isIndividualPassedStage(4) ? <span className={"checked-icon-container"}><CheckIcon/></span> : null} Click finish or reject (once you'll finish all persons, the visas / rejections will be sent automatically to group owner email)
                        <br/>
                        <br/>
                    </div>
                </div>}
                {lodashGet(apiResult, 'data.type') == 'individual' && <div className={"individual-short-instructions-container"}>
                    1){isIndividualPassedStage(1) ? <span className={"checked-icon-container"}><CheckIcon/></span> : null} Click "start procedure"
                    <br/>
                    2) {isUploadedVisa() ? <span className={"checked-icon-container"}><CheckIcon/></span> : null} Upload visa files in the end
                    <br/>
                    3) {isIndividualPassedStage(3) ? <span className={"checked-icon-container"}><CheckIcon/></span> : null} Click finish or reject (once you'll finish, the visa or the rejection will be sent automatically to the person email)
                    <br/>
                    <br/>
                </div>}
                <table className={"main-table-component"} border="1">
                    {lodashGet(apiResult, 'data.type') == 'group' && <thead>
                        <tr>
                            <th className={"sticky-header"}>
                                Person select <br/><br/>{getGroupPersons()}
                            </th>
                        </tr>
                    </thead>}
                    <tbody>
                        <tr>
                            <td>
                                {selectedPersonToView != null && <div>
                                    <div className="start-procedure-button-container">
                                        <Button disabled={isIndividualPassedStage(2)} className={"agent-action-button"} onClick={startProcedure}>Start Procedure {loaders.startProcedure && <>&nbsp;<Loader /></>}</Button>
                                        {errors.startProcedure && <div className={"error-message-container"}>Failed to start procedure</div>}
                                    </div>
                                    <div className={"files-information-container"}>
                                        <hr/>
                                        <h2 className={"table-main-h2"}>User Files:</h2>
                                        <hr/>
                                        <br/><br/>
                                        {imagesList.map((item, itemIndex) => (<React.Fragment key={itemIndex}>
                                            <div className={"file-item-container"}>
                                                <b>{itemIndex+1}) {item.displayText}</b>
                                                <br/>
                                                <input type="file" className={'user-file-upload'} id={item.fieldId} onChange={(e) => { onFileUploadChange(e, item.key, `.client-pictures-item-container.${item.key}`); }} />
                                                {getPersonFileType(item.key) && <React.Fragment>
                                                    <div className={`client-pictures-item-container ${item.key}`}>
                                                        {getPersonFileType(item.key).showAsPdf ? <PdfHandler base64Data={getPersonFileType(item.key).fileContent} controls={{}} /> : <img className={`client-pictures-component ${item.key}`} src={getPersonFileType(item.key).fileContent} alt={""} />}
                                                        <CropHandler controls={cropHandlerControls[item.key]} dimensions={isMobile ? CONSTANTS.CROP_TOOL_DIMENSIONS[item.key +'Mobile'] : CONSTANTS.CROP_TOOL_DIMENSIONS[item.key +'Desktop']} imageCssSelector={`.client-pictures-component.${item.key}`} imageSrc={lodashGet(modifiedUserFileObjects, `${item.key}.fileContent`)} />
                                                    </div>

                                                    <br/>
                                                    {lodashGet(modifiedUserFileObjects, `${item.key}.finished`) && <><div><Button className={"user-files-action-button"} onClick={() => { setModifiedUserFileObjects({ ...modifiedUserFileObjects, [item.key]: null }); }}>Undo changes</Button></div></>}
                                                    <br/>
                                                    {(!getPersonFileType(item.key).showAsPdf && (!lodashGet(modifiedUserFileObjects, `${item.key}.fileContent`) || lodashGet(modifiedUserFileObjects, `${item.key}.finished`))) && <Button className={"user-files-action-button"} onClick={() => { setModifiedUserFileObjects({ ...modifiedUserFileObjects, [item.key]: { fileContent: getPersonFileType(item.key).fileContent } }); cropHandlerControls[item.key].restart(); }}>Edit</Button>}
                                                    <br/><br/>
                                                    {(!lodashGet(modifiedUserFileObjects, `${item.key}.fileContent`) || lodashGet(modifiedUserFileObjects, `${item.key}.finished`)) && <Button className={"user-files-action-button"} onClick={() => { document.querySelector('#' + item.fieldId).click(); }}>Upload new image</Button>}
                                                    {lodashGet(modifiedUserFileObjects, `${item.key}.finished`) && <><div><br/><Button className={"user-files-action-button final-upload-confirmation"} onClick={() => { saveUserFile(selectedPersonToView, item.key, `saveImage-${item.key}`); }}>Upload image to server {loaders[`saveImage-${item.key}`] && <Loader />}</Button></div></>}
                                                    {lodashGet(modifiedUserFileObjects, `${item.key}.error`) && <><div className={"warning-color upload-error-message"}><br/>{lodashGet(modifiedUserFileObjects, `${item.key}.error`)}</div></>}
                                                </React.Fragment>}
                                                {!getPersonFileType(item.key) && <React.Fragment>
                                                    <div><Button className={"user-files-action-button"} onClick={() => { document.querySelector('#' + item.fieldId).click(); }}>Upload new image</Button></div>
                                                    <span className={"warning-color"}>None. Please contact support if required (at the bottom)</span>
                                                </React.Fragment>}
                                            </div>
                                            <br/><br/>
                                        </React.Fragment>))}
                                    </div>
                                    <hr/>
                                    <div>
                                        <h2 className={"table-main-h2"}>User Form ({selectedPersonToView.key}) details:</h2>
                                    </div>
                                    <hr/>
                                    <h3><u>Applicant Information:</u></h3>
                                    <div className="stage-content-container stage2">
                                        {stage2Chunks.map((chunkArray, chunkArrayIndex) => {
                                            return <Grid key={chunkArrayIndex} className={"fields-row-container" + (stage2Chunks.length == chunkArrayIndex + 1 ? ' last' : '')} container>{chunkArray.map((fieldItem, fieldItemIndex) => {
                                                return <Grid key={chunkArrayIndex + '_' + fieldItemIndex} item data-key={fieldItem.key} className={"field-container"} md={3}>
                                                    <b>{fieldItem.description}</b>
                                                    <br/>
                                                    {fieldItem.render(lodashGet(selectedPersonToView, `stage2Data.${fieldItem.key}`))}
                                                </Grid>;
                                            })}</Grid>;
                                        })}
                                        <h3 className={'align-left'}>Visa Type</h3>
                                        <div className={"align-left"}><b>Duration</b> - {getLanguageValue('register-individual.stage2-select-visa-type-' + lodashGet(selectedPersonToView, 'stage2Data.visaType'))}</div>
                                        {!isInactiveField('stage2', 'visaExpedited') && <div className={'align-left'}><b>שדרוג לויזה רב פעמית</b> - {lodashGet(selectedPersonToView, 'stage2Data.visaExpeditedFlag') ? 'Yes' : 'No'}</div>}
                                        <div className={'align-left'}><b>Arrival Date</b> - {moment(lodashGet(selectedPersonToView, 'stage2Data.arrivalDate')).format(CONSTANTS.DATE_FORMAT_MOMENT)}</div>
                                        {lodashGet(apiResult, 'data.type') == 'group' ? <>
                                                <h3 className={'align-left'}>Visa Delivery Type (GROUP)</h3>
                                                <div className={"align-left"}><b>Type</b> - {getLanguageValue('register-individual.stage2-select-visa-delivery-type-' + (lodashGet(apiResult, 'data.groupApplication.deliveryType') || ''))}</div>
                                                <div className={"align-left"}><b>Area display text</b> - {CONSTANTS?.PRICES?.delivery.find((item) => item.key == lodashGet(apiResult, 'data.groupApplication.deliveryDestination'))?.displayText}</div>
                                                <div className={"align-left"}><b>Area code</b> - {lodashGet(apiResult, 'data.groupApplication.deliveryDestination')}</div>
                                                <div className={"align-left"}><b>Address</b> - {lodashGet(apiResult, 'data.groupApplication.addressLine')}, {lodashGet(apiResult, 'data.groupApplication.city')}</div>
                                        </> :
                                            <>
                                                <h3 className={'align-left'}>Visa Delivery Type (INDIVIDUAL)</h3>
                                                <div className={"align-left"}><b>Type</b> - {getLanguageValue('register-individual.stage2-select-visa-delivery-type-' + (lodashGet(selectedPersonToView, 'stage2Data.deliveryType') || ''))}</div>
                                                <div className={"align-left"}><b>Area display text</b> - {CONSTANTS?.PRICES?.delivery.find((item) => item.key == lodashGet(selectedPersonToView, 'stage2Data.deliveryDestination'))?.displayText}</div>
                                                <div className={"align-left"}><b>Area code</b> - {lodashGet(selectedPersonToView, 'stage2Data.deliveryDestination')}</div>
                                            </>
                                        }


                                        <br/>
                                        <div className={"citizen-of-any-other-country-question"}>Citizen of other countries - <b>{lodashGet(selectedPersonToView, 'stage2Data.questionAreYouACitizenOfOtherCountry')}</b></div>
                                        {lodashGet(selectedPersonToView, 'stage2Data.questionAreYouACitizenOfOtherCountry') == 'yes' && <><table className={"citizen-of-other-country-table table-component"}>
                                            <thead>
                                            <tr className={"citizen-of-other-country-table-header table-header-component"}>
                                                <td>Country of citizenship or nationality</td>
                                                <td>How citizenship received</td>
                                                <td>Other comment</td>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                selectedPersonToView.stage2Data.currentlyCitizenOfCountries && selectedPersonToView.stage2Data.currentlyCitizenOfCountries.length > 0 && selectedPersonToView.stage2Data.currentlyCitizenOfCountries.map(function(item, itemIndex) {
                                                    return <tr key={itemIndex}>
                                                        <td>
                                                            {item.countryOfCitizenship}
                                                        </td>
                                                        <td>
                                                            {item.howCitizenshipReceived}
                                                        </td>
                                                        <td>
                                                            {item.other}
                                                        </td>
                                                    </tr>;
                                                })
                                            }
                                            </tbody>
                                        </table></>}
                                        <div className="citizen-of-any-other-country-question">Was a citizen of other countries - <b>{lodashGet(selectedPersonToView, 'stage2Data.questionWereYouACitizenOfOtherCountry')}</b></div>
                                        {lodashGet(selectedPersonToView, 'stage2Data.questionWereYouACitizenOfOtherCountry') == 'yes' && <><table className={"citizen-of-other-country-table table-component"}>
                                            <thead>
                                            <tr className={"citizen-of-other-country-table-header table-header-component"}>
                                                <td>Country of citizenship or nationality</td>
                                                <td>Citizenship start date (dd/mm/yyyy)</td>
                                                <td>Citizenship end date (dd/mm/yyyy)</td>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                lodashGet(selectedPersonToView, 'stage2Data.previousCitizenOfCountries.length') > 0 && selectedPersonToView.stage2Data.previousCitizenOfCountries.map(function(item, itemIndex) {
                                                    return <tr key={itemIndex}>
                                                        <td>
                                                            {item.countryOfCitizenship}
                                                        </td>
                                                        <td>
                                                            {moment(item.startDate).format('DD/MM/YYYY')}
                                                        </td>
                                                        <td>
                                                            {moment(item.endDate).format('DD/MM/YYYY')}
                                                        </td>
                                                    </tr>;
                                                })
                                            }
                                            </tbody>
                                        </table></>}
                                    </div>
                                    <div className="stage-content-container stage3">
                                        <hr/>

                                        <h3><u>Personal Information:</u></h3>
                                        {stage3Chunks.map((chunkArray, chunkArrayIndex) => {
                                            return <Grid key={chunkArrayIndex} className={"fields-row-container" + (chunkArray.length == chunkArrayIndex + 1 ? ' last' : '')} container>{chunkArray.map((fieldItem, fieldItemIndex) => {
                                                return <Grid key={chunkArrayIndex + '_' + fieldItemIndex} item data-key={fieldItem.key} className={"field-container"} md={3}>
                                                    <b>{fieldItem.description}</b>
                                                    <br/>
                                                    {fieldItem.render(lodashGet(selectedPersonToView, `stage3Data.${fieldItem.key}`))}
                                                </Grid>;
                                            })}</Grid>;
                                        })}
                                        <div className={"stage3-phones-container"}>
                                            <b>Phones:</b>
                                            <table className={"stage3-phones-table table-component"}>
                                                <thead>
                                                <tr className={"stage3-phones-table-header table-header-component"}>
                                                    <td>Type</td>
                                                    <td>Country code</td>
                                                    <td>Phone number</td>
                                                </tr>
                                                </thead>
                                                <tbody>
                                                {
                                                    lodashGet(selectedPersonToView, 'stage3Data.phones.length') > 0 && selectedPersonToView.stage3Data.phones.map(function(item, itemIndex) {
                                                        return <tr key={itemIndex}>
                                                            <td>
                                                                {item.telephoneType}
                                                            </td>
                                                            <td>
                                                                {item.countryCode}
                                                            </td>
                                                            <td>
                                                                {item.phoneNumber}
                                                            </td>
                                                        </tr>;
                                                    })
                                                }
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                    <hr style={{ display: 'none' }}/>
                                    <h3 style={{ display: 'none' }}><u>Flight Information:</u></h3>
                                    <div  style={{ display: 'none' }} className="stage-content-container stage4">
                                        <Grid className={"fields-row-container"} container>
                                            <Grid item className={"field-container"} md={3}>
                                                <b>Flight Date</b>
                                                <br/>
                                                {moment(lodashGet(selectedPersonToView, 'stage4Data.flightDate')).format('DD/MM/YYYY')}
                                            </Grid>
                                            <Grid item className={"field-container"} md={3}>
                                                <b>Flight Number</b>
                                                <br/>
                                                {lodashGet(selectedPersonToView, 'stage4Data.flightNumber')}
                                            </Grid>
                                            <Grid item className={"field-container"} md={3}>
                                                <b>Flight Company</b>
                                                <br/>
                                                {selectedFlightCompanyDisplayText}
                                            </Grid>
                                        </Grid>
                                        <h4>Flight Hotel Destination Information</h4>
                                        <Grid className={"fields-row-container"} container>
                                            <Grid item className={"field-container"} md={3}>
                                                <b>Hotel Name</b>
                                                <br/>
                                                {lodashGet(selectedPersonToView, 'stage4Data.flightDestinationHotelName')}
                                            </Grid>
                                            <Grid item className={"field-container"} md={3}>
                                                <b>Hotel Address</b>
                                                <br/>
                                                {lodashGet(selectedPersonToView, 'stage4Data.flightDestinationHotelAddress')}
                                            </Grid>
                                        </Grid>
                                        <h4>Contact Information</h4>
                                        <Grid className={"fields-row-container"} container>
                                            <Grid item className={"field-container"} md={3}>
                                                <b>Contact Name</b>
                                                <br/>
                                                {lodashGet(selectedPersonToView, 'stage4Data.contactName')}
                                            </Grid>
                                            <Grid item className={"field-container"} md={3}>
                                                <b>Contact Address Line 1</b>
                                                <br/>
                                                {lodashGet(selectedPersonToView, 'stage4Data.contactAddressLine1')}
                                            </Grid>
                                            <Grid item className={"field-container"} md={3}>
                                                <b>Contact Address Line 2</b>
                                                <br/>
                                                {lodashGet(selectedPersonToView, 'stage4Data.contactAddressLine2')}
                                            </Grid>
                                            <Grid item className={"field-container"} md={3}>
                                                <b>Contact Apartment Number</b>
                                                <br/>
                                                {lodashGet(selectedPersonToView, 'stage4Data.contactApartmentNumber')}
                                            </Grid>
                                        </Grid>
                                        <Grid className={"fields-row-container"} container>
                                            <Grid item className={"field-container"} md={3}>
                                                <b>Contact city</b>
                                                <br/>
                                                {lodashGet(selectedPersonToView, 'stage4Data.contactCity')}
                                            </Grid>
                                        </Grid>
                                        <h4>Address while in country</h4>
                                        {!lodashGet(selectedPersonToView, 'stage4Data.addressWhileInCountryIsLikeHotelAddressFlag') ? <><Grid className={"fields-row-container"} container>
                                            <Grid item className={"field-container"} md={3}>
                                                <b>Addres line 1</b>
                                                <br/>
                                                {lodashGet(selectedPersonToView, lodashGet(selectedPersonToView, 'stage4Data.addressWhileInCountryIsLikeContactAddressFlag') ? 'stage4Data.contactAddressLine1' : 'stage4Data.addressLine1')}
                                            </Grid>
                                            <Grid item className={"field-container"} md={3}>
                                                <b>Address Line 2</b>
                                                <br/>
                                                {lodashGet(selectedPersonToView, lodashGet(selectedPersonToView, 'stage4Data.addressWhileInCountryIsLikeContactAddressFlag') ? 'stage4Data.contactAddressLine2' : 'stage4Data.addressLine2')}
                                            </Grid>
                                            <Grid item className={"field-container"} md={3}>
                                                <b>Apartment Number</b>
                                                <br/>
                                                {lodashGet(selectedPersonToView, lodashGet(selectedPersonToView, 'stage4Data.addressWhileInCountryIsLikeContactAddressFlag') ? 'stage4Data.contactApartmentNumber' : 'stage4Data.apartmentNumber')}
                                            </Grid>
                                            <Grid item className={"field-container"} md={3}>
                                                <b>City</b>
                                                <br/>
                                                {lodashGet(selectedPersonToView, lodashGet(selectedPersonToView, 'stage4Data.addressWhileInCountryIsLikeContactAddressFlag') ? 'stage4Data.contactCity' : 'stage4Data.city')}
                                            </Grid>
                                        </Grid></> : <Grid item className={"field-container"} md={3}>
                                            {lodashGet(selectedPersonToView, 'stage4Data.flightDestinationHotelAddress')}
                                        </Grid>}
                                        <h4>Contact in case of emergency</h4>
                                        <Grid className={"fields-row-container"} container>
                                            <Grid item className={"field-container"} md={3}>
                                                <b>Contact name</b>
                                                <br/>
                                                {lodashGet(selectedPersonToView, 'stage4Data.emergencyContactFirstName') + ' ' + lodashGet(selectedPersonToView, 'stage4Data.emergencyContactLastName')}
                                            </Grid>
                                            <Grid item className={"field-container"} md={3}>
                                                <b>Contact email</b>
                                                <br/>
                                                {lodashGet(selectedPersonToView, 'stage4Data.emergencyContactEmail')}
                                            </Grid>
                                            <Grid item className={"field-container"} md={3}>
                                                <b>Contact phone</b>
                                                <br/>
                                                {lodashGet(selectedPersonToView, 'stage4Data.emergencyContactPhoneCountryCode') + lodashGet(selectedPersonToView, 'stage4Data.emergencyContactPhoneNumber')}
                                            </Grid>
                                        </Grid>
                                    </div>
                                    <hr/>
                                    <h3><u>Personal Questions:</u></h3>
                                    <div className={"stage5-questions-container"}>
                                        {getStage5QuestionsList(lodashGet(selectedPersonToView, 'stage5Data')).map((questionItem, questionItemIndex) => (
                                            <Grid key={questionItemIndex} className={"question-and-input-container question-key-" + questionItem.key}>
                                                <div dangerouslySetInnerHTML={{ __html: questionItem.value }} />
                                                <div className={"answer-container"}>
                                                    <b>{questionItem.answer}</b>
                                                </div>
                                            </Grid>
                                        ))}
                                    </div>
                                    <div>
                                        <h2 className={"table-main-h2"}>Upload Visa files {isUploadDisabled('title') && <span className={"disabled-notification"}>(Disabled until you will click "START PROCEDURE")</span>}</h2>
                                    </div>
                                    <div className={"upload-visa-container"}>
                                        <div className={"upload-visa-dropzone-container" + (isUploadDisabled() ? ' inactive' : '')}>
                                            <DropzoneAreaBase
                                                dropzoneClass={"upload-visa-dropzone"}
                                                previewGridClasses={{
                                                    container: 'upload-visa-dropzone-preview-container',
                                                    item: 'upload-visa-dropzone-preview-item',
                                                    image: 'upload-visa-dropzone-preview-image'
                                                }}
                                                dropzoneParagraphClass={"upload-visa-dropzone-paragraph"}
                                                fileObjects={fileObjects}
                                                showPreviews={true}
                                                showFileNamesInPreview={true}
                                                showPreviewsInDropzone={false}
                                                onAdd={newFileObjs => {
                                                    if (isUploadDisabled()) {
                                                        return false;
                                                    }
                                                    setFileObjects([].concat(fileObjects, newFileObjs));
                                                }}
                                                onDelete={deleteFileObj => {
                                                    if (isUploadDisabled()) {
                                                        return false;
                                                    }
                                                    setFileObjects(fileObjects.filter(item => item !== deleteFileObj));
                                                    setFileObjectsToDelete([ ...fileObjectsToDelete, deleteFileObj ]);
                                                }}
                                            />
                                        </div>
                                        <Button disabled={isUploadDisabled()} className={"upload-all-files-button"} onClick={uploadAllVisaFiles}>Save files changes {loaders.uploadAllFiles ? <>&nbsp;<Loader /></> : null}</Button>
                                        {errors.uploadAllFiles && <div className={"upload-error-container"}>failed to save file changes. Please refresh the page and try again</div>}
                                    </div>
                                    <div>
                                        <h2 className={"table-main-h2"}>Finish</h2>
                                    </div>
                                    <Button disabled={isFinishDisabled()} className={"finish-button"} onClick={() => finish('ready')}>Finish {loaders.ready && <Loader />}</Button>
                                    <br/>
                                    <Button disabled={isFinishDisabled({ ignoreFiles: true })} className={"finish-button"} onClick={() => finish('readyWithoutFiles')}>Finish without files{loaders.readyWithoutFiles && <Loader />}</Button>
                                    <br/>
                                    <Button disabled={isRejectDisabled()} className={"reject-button"} onClick={() => finish('reject')}>Reject With Reason {loaders.reject && <Loader />}</Button>
                                    {showRejectWarning && <div className={"reject-reason-error-text"}>Please select at least 1 reject reason</div>}
                                    <br/>
                                    <div className={"reject-reasons-container" + (showRejectWarning ? ' warning' : '')}>
                                        <div className={""}>REJECT REASONS:</div>
                                        <table className={'reject-reasons-table'} style={{ 'text-align': 'left' }}>
                                            <tr>
                                                <td>Images:</td>
                                                <td>
                                                    {rejectReasonsImageFields.map((imageFieldItem, imageFieldItemIndex) => (imageFieldItem.isDisplayed && <FormControlLabel
                                                        key={imageFieldItemIndex}
                                                        className={`reject-reason-${imageFieldItem.key}`}
                                                        control={
                                                            <Checkbox
                                                                disabled={loaders.reject || isRejectDisabled()}
                                                                checked={rejectReasons[imageFieldItem.key] || false}
                                                                onChange={(e) => { setShowRejectWarning(false); setRejectReasons({...rejectReasons, [imageFieldItem.key]: e.target.checked}); }}
                                                                name={`reject-reason-${imageFieldItem.key}`}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={imageFieldItem.label}
                                                    />))}
                                                </td>
                                            </tr>
                                            <tr>
                                                <td>Input Fields:</td>
                                                <td>
                                                    {rejectReasonsInputFields.map((inputFieldItem, inputFieldItemIndex) => (inputFieldItem.isDisplayed && <FormControlLabel
                                                        key={inputFieldItemIndex}
                                                        className={`reject-reason-${inputFieldItem.key}`}
                                                        control={
                                                            <Checkbox
                                                                disabled={loaders.reject || isRejectDisabled()}
                                                                checked={rejectReasons[inputFieldItem.key] || false}
                                                                onChange={(e) => { setShowRejectWarning(false); setRejectReasons({...rejectReasons, [inputFieldItem.key]: e.target.checked}); }}
                                                                name={`reject-reason-${inputFieldItem.key}`}
                                                                color="primary"
                                                            />
                                                        }
                                                        label={inputFieldItem.label}
                                                    />))}
                                                </td>
                                            </tr>
                                        </table>
                                    </div>
                                    <br/>
                                    <Button disabled={isRejectDisabled()} className={"reject-button"} onClick={() => finish('rejectPermanently')}>Reject Permanently {loaders.rejectPermanently && <Loader />}</Button>
                                </div>}
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            </> : null}
            <div className={"support-container"}>
                <b>Need help? contact at <a href={"mailto:" + CONSTANTS.SUPPORT_EMAIL}>{CONSTANTS.SUPPORT_EMAIL}</a></b>
            </div>
        </AgentManagerStyle>
        <Modal
            id="modal-component"
            disableBackdropClick={false}
            open={isModalOpen}
            onClose={() => { setIsModalOpen(false); }}
            aria-labelledby="modal-title"
            aria-describedby="modal-content"
        >
            <ModalComponentStyle>
                <div>
                    <div className={"positioning-container"}>
                        <div id="modal-title">Visa Sent!</div>
                        <div id="modal-content">
                            Notification email regarding visa(s)/rejection(s) was sent to the customer
                            <div className={"close-button-container"}><Button onClick={() => { setIsModalOpen(false); }}>Close</Button></div>
                        </div>
                    </div>
                </div>
            </ModalComponentStyle>
        </Modal>
    </>;

    function isUploadedVisa() {
        if (!lodashGet(selectedPersonToView, 'files')) {
            return false;
        }
        if (fileObjects.find((item) => item.offline)) {
            return true;
        }
        //if (lodashGet(selectedPersonToView, 'files').find((item) => item.fileType == 'visa')) {
            //return true;
        //}
        return false;
    }

    async function startProcedure() {
        try {
            if (!selectedPersonToView) {
                return;
            }
            if (isLoaderActive()) {
                return;
            }
            setLoaders({ ...loaders, startProcedure: true });
            setErrors({ ...errors, startProcedure: false });
            let url = getApiRoot() + 'agent-api/agent-change-application-status/';
            let recaptchaToken = await googleRecaptcha();
            let options = {
                ...CONSTANTS.POST_DEFAULT_OPTIONS,
                body: JSON.stringify({
                    recaptchaToken,
                    secretAgentKey: routerProps.id,
                    key: selectedPersonToView.key,
                    status: 'waiting-for-agent',
                    rejectReasons: {}
                })
            };
            let result = await (await fetch(url, options)).json();
            if (lodashGet(result, 'data.success')) {
                // good
                setSelectedPersonToView({ ...selectedPersonToView, status: 'waiting-for-agent' });
            }
            else {
                // error
                setErrors({ ...errors, startProcedure: true });
            }
        }
        catch (err) {
            // nothing to do
            setErrors({ ...errors, startProcedure: true });
        }
        setLoaders({ ...loaders, startProcedure: false });
    }

    function getGroupPersons() {
        let list = lodashGet(apiResult, 'data.linkedIndividualApplications');
        if (Array.isArray(list)) {
            return list.map((item, itemIndex) => (<React.Fragment key={itemIndex}><Button className={"select-person-button" + (selectedPersonToView && selectedPersonToView.key == item.key ? ' selected-person' : '')} onClick={() => selectPersonFromGroup(item)}>{isGroupPersonReady(item) ? <CheckIcon/> : null}{'#' + (itemIndex+1) + ' - ' + item.stage2Data.firstNameEnglish + ' ' + item.stage2Data.lastNameEnglish}</Button>&nbsp;&nbsp;&nbsp;</React.Fragment>));
        }
        return null;
    }

    function selectPersonFromGroup(item) {
        setSelectedPersonToView(item);
        setModifiedUserFileObjects({});
        // set files if needed
        fileObjects = [];
        for (let loop = 0 ; loop < item.files.length ; ++loop) {
            if (item.files[loop].fileType == 'visa') {
                fileObjects.push({ offline: true, fileId: item.files[loop].fileId, data: '', file: { type: '', name: item.files[loop].filename } });
            }
        }
        setFileObjects(fileObjects);
    }

    function getStage5QuestionsList(stage5Data) {
        let existingQuestionsAndAnswers = {};
        if (lodashGet(stage5Data, 'questionsAndAnswers')) {
            // save mapping of key / answer to set in next code
            for (let questionKey in stage5Data.questionsAndAnswers) {
                existingQuestionsAndAnswers[ questionKey ] = stage5Data.questionsAndAnswers[questionKey];
            }
        }

        const output = [];
        const questionsKeysArray = getLanguageValue('register-individual.stage5-questions-array').replace(/\s/g, '').split(',');
        for (let questionKeySuffix of questionsKeysArray) {
            const fullKey = 'register-individual.stage5-questions-item-' + questionKeySuffix;
            const questionValue = getLanguageValue(fullKey);
            if (questionValue) {
                output.push({ key: questionKeySuffix, value: questionValue, answer: existingQuestionsAndAnswers[questionKeySuffix] || '' });
            }
        }
        return output;
    }

    function isUploadDisabled(type) {
        if (type == 'title') {
            return lodashGet(selectedPersonToView, 'status') == 'paid';
        }
        return isLoading || lodashGet(selectedPersonToView, 'status') != 'waiting-for-agent';
    }

    async function uploadAllVisaFiles() {
        if (isLoaderActive() || isLoading || (!fileObjects.length && !fileObjectsToDelete.length)) {
            return;
        }
        let isOnline = false;
        let uploadCounter = fileObjects.length;
        let uploadFailureCounter = 0;
        for (let loop = 0 ; loop < fileObjects.length ; ++loop) {
            // run uploads
            if (!fileObjects[loop].offline) {
                isOnline = true;
                (async function(fileItemValue) {
                    try {
                        let url = getApiRoot() + 'agent-api/agent-upload-visa-files/?type=' + lodashGet(apiResult, 'data.type');
                        let recaptchaToken = await googleRecaptcha();
                        let options = {
                            ...CONSTANTS.POST_DEFAULT_OPTIONS,
                            body: JSON.stringify({
                                recaptchaToken,
                                secretAgentKey: routerProps.id,
                                key: selectedPersonToView.key,
                                filename: fileItemValue.file.name,
                                fileContent: fileItemValue.data
                            })
                        };
                        let result = await (await fetch(url, options)).json();
                        uploadCounter--;
                        if (!lodashGet(result, 'data.success')) {
                            ++uploadFailureCounter;
                        }
                        else {
                            fileItemValue.fileId = lodashGet(result, 'data.fileId');
                            fileItemValue.offline = true;
                        }
                    }
                    catch (err) {
                        // error
                    }
                    if (uploadCounter == 0) {
                        // all completed
                        setLoaders({ ...loaders, uploadAllFiles: false });
                        setIsLoading(false);
                        if (uploadFailureCounter) {
                            setErrors({ ...errors, uploadAllFiles: true });
                        }
                        setFileObjects([].concat(fileObjects));
                    }
                })(fileObjects[loop]);
            }
            else {
                uploadCounter--;
            }
        }
        let deleteCounter = fileObjectsToDelete.length;
        let deleteFailureCounter = 0;
        for (let loop = 0 ; loop < fileObjectsToDelete.length ; ++loop) {
            (async function(fileItemValue) {
                try {
                    let url = getApiRoot() + 'agent-api/agent-remove-visa-files/?type=' + lodashGet(apiResult, 'data.type');
                    let recaptchaToken = await googleRecaptcha();
                    let options = {
                        ...CONSTANTS.POST_DEFAULT_OPTIONS,
                        body: JSON.stringify({
                            recaptchaToken,
                            secretAgentKey: routerProps.id,
                            key: selectedPersonToView.key,
                            fileId: fileItemValue.fileId
                        })
                    };
                    let result = await (await fetch(url, options)).json();
                    deleteCounter--;
                    if (!lodashGet(result, 'data.success')) {
                        ++deleteFailureCounter;
                    }
                }
                catch (err) {
                    // error
                    deleteCounter--
                }
                if (deleteCounter == 0) {
                    // all completed
                    setLoaders({ ...loaders, uploadAllFiles: false });
                    setIsLoading(false);
                    if (deleteFailureCounter) {
                        setErrors({ ...errors, uploadAllFiles: true });
                    }
                    setFileObjectsToDelete([]);
                }
            })(fileObjectsToDelete[loop], loop);
        }
        if (isOnline) {
            setErrors({ ...errors, uploadAllFiles: false});
            setLoaders({ ...loaders, uploadAllFiles: true });
            setIsLoading(true);
        }
    }

    function isFinishDisabled(conf) {
        // check status is not ready or reject (submitted) and that there are submitted visa files
        if (['paid', 'ready', 'ready-without-files', 'reject'].indexOf(lodashGet(selectedPersonToView, 'status')) != -1) {
            return true;
        }
        if (!lodashGet(conf, 'ignoreFiles')) {
            let haveSavedVisa = false;
            for (let loop = 0 ; loop < fileObjects.length ; ++loop) {
                if (fileObjects[loop].offline) {
                    haveSavedVisa = true;
                }
            }
            return !haveSavedVisa;
        }
        return false;
    }

    function isRejectDisabled() {
        // check only status is not ready or reject
        if (!selectedPersonToView || ['ready', 'ready-without-files', 'reject'].indexOf(lodashGet(selectedPersonToView, 'status')) > -1) {
            return true;
        }
        return false;
    }

    function isGroupReady() {
        return lodashGet(apiResult, 'data.groupApplication.status') == 'ready';
    }

    function isGroupPersonReady(person) {
        return ['ready', 'ready-without-files'].indexOf(lodashGet(person, 'status')) != -1;
    }

    function isIndividualPassedStage(stageNumber) {
        const personStatus = lodashGet(selectedPersonToView, 'status');
        switch (stageNumber) {
            case 1:
                return ['waiting-for-agent', 'ready', 'ready-without-files', 'reject'].indexOf(personStatus) != -1;
            case 2:
                return ['waiting-for-agent', 'ready', 'ready-without-files', 'reject'].indexOf(personStatus)  != -1;
            case 3:
                return ['ready', 'ready-without-files', 'reject'].indexOf(personStatus) != -1;
            case 4:
                return ['ready', 'ready-without-files'].indexOf(personStatus) != -1;
        }
        return false;
    }

    async function finish(status) {
        let STATUS_MAPPING = {
            reject: 'reject',
            ready: 'ready',
            readyWithoutFiles: 'ready-without-files',
            rejectPermanently: 'reject'
        };
        try {
            if (isLoaderActive() || isLoading) {
                return;
            }
            if (status == 'reject') {
                let validateResult = validateReject();
                if (validateResult.length) {
                    setShowRejectWarning(true);
                    return;
                }
            }
            setShowRejectWarning(false);
            setIsLoading(true);
            setLoaders({ ...loaders, [status]: true });
            let url = getApiRoot() + 'agent-api/agent-change-application-status/';
            let recaptchaToken = await googleRecaptcha();
            let rejectReasonsPositiveValues = {};
            for (let key in rejectReasons) {
                if (rejectReasons[key]) {
                    rejectReasonsPositiveValues[key] = true;
                }
            }
            let options = {
                ...CONSTANTS.POST_DEFAULT_OPTIONS,
                body: JSON.stringify({
                    recaptchaToken,
                    secretAgentKey: routerProps.id,
                    key: selectedPersonToView.key,
                    status: STATUS_MAPPING[status],
                    rejectReasons: (status == 'reject' ? rejectReasonsPositiveValues : {})
                })
            };
            let result = await (await fetch(url, options)).json();
            if (lodashGet(result, 'data.success')) {
                // good
                setSelectedPersonToView({ ...selectedPersonToView, status: STATUS_MAPPING[status] });
                if (lodashGet(apiResult, 'data.linkedIndividualApplications.length')) {
                    for (let loop = 0 ; loop < apiResult.data.linkedIndividualApplications.length ; ++loop) {
                        if (apiResult.data.linkedIndividualApplications[loop].key == selectedPersonToView.key) {
                            apiResult.data.linkedIndividualApplications[loop].status = 'ready';
                            apiResult.data.linkedIndividualApplications[loop].files = selectedPersonToView.files;
                        }
                    }
                }
                setApiResult({...apiResult});
                if (lodashGet(apiResult, 'data.type') != 'group' || lodashGet(result, 'data.groupReady')) {
                    setIsModalOpen(true);
                }

            }
            else {
                // error
                setErrors({ ...errors, finish: true });
            }
            setLoaders({ ...loaders, [status]: false });
        }
        catch (err) {
            // nothing to do
            setLoaders({ ...loaders, [status]: false });
        }
        setIsLoading(false);
    }

    function validateReject() {
        let output = [];
        let haveRejectReason = false;
        for (let key in rejectReasons) {
            if (rejectReasons[key]) {
                haveRejectReason = true;
            }
        }
        if (!haveRejectReason) {
            output.push('rejectReason');
        }
        return output;
    }

    function getPersonFileType(fileType) {
        const editedResult = lodashGet(modifiedUserFileObjects, fileType);
        const serverResult = selectedPersonToView.files.find((fileItem, fileItemIndex) => fileItem.fileType == fileType);
        if (editedResult) {
            return editedResult;
        }
        else if (serverResult) {
            return serverResult;
        }
        return false;
    }

    function onFileUploadChange(e, fieldName, containerCssSelector) {
        const file = e.target.files[0];
        const localFilename = file.name;
        const reader = new FileReader();
        // reset to allow selecting same filename
        e.target.value = '';

        reader.addEventListener("load", async function () {
            // validate
            let fileTypeResult = await getFileType(reader.result);
            let fileTypeResultExtension = lodashGet(fileTypeResult, 'ext');
            if (['jpg', 'jpeg', 'png', 'bmp', 'heic', 'pdf'].indexOf(fileTypeResultExtension) == -1) {
                setModifiedUserFileObjects({ ...modifiedUserFileObjects, [fieldName]: { error: getLanguageValue('register-individual.stage2-upload-passport-type-error') } })
                return;
            }
            if (reader.result.length < CONSTANTS.FILESIZE_MIN_LIMIT) {
                setModifiedUserFileObjects({ ...modifiedUserFileObjects, [fieldName]: { error: getLanguageValue('register-individual.stage2-upload-passport-too-small-error') } })
                return;
            }
            if (reader.result.length > CONSTANTS.FILESIZE_MAX_LIMIT) {
                setModifiedUserFileObjects({ ...modifiedUserFileObjects, [fieldName]: { error: getLanguageValue('register-individual.stage2-upload-passport-size-error') } })
                return;
            }
            // close previous crop tool if have any
            if (document.querySelector(containerCssSelector + ' .image-crop-buttons-container .crop-finish-button')) {
                document.querySelector(containerCssSelector + ' .image-crop-buttons-container .crop-finish-button').click();
            }
            if (fileTypeResultExtension == 'pdf') {
                // destory crop tool
                setTimeout(() => {
                    cropHandlerControls[fieldName].destroy();
                }, 100);
                // enable display of pdf
                setModifiedUserFileObjects({ ...modifiedUserFileObjects, [fieldName]: { fileContent: reader.result, showAsPdf: true, finished: true } });
                return;
            }
            // activate crop tool
            setTimeout(() => {
                setModifiedUserFileObjects({ ...modifiedUserFileObjects, [fieldName]: { fileContent: reader.result } });
                setTimeout(() => {
                    cropHandlerControls[fieldName].restart();
                }, 100);
            }, 100);
        }, false);

        if (file) {
            reader.readAsDataURL(file);
        }
    }

    async function saveUserFile(selectedPerson, fileType, loaderName) {
        setLoaders({ ...loaders, [loaderName]: true });
        setIsLoading(true);
        try {
            let url = getApiRoot() + 'agent-api/agent-replace-client-image/?type=' + (lodashGet(apiResult, 'data.type') == 'group' ? 'group' : 'individual');
            let recaptchaToken = await googleRecaptcha();
            let fileTypeResult = await getFileType(modifiedUserFileObjects[fileType].fileContent);
            let fileTypeResultExtension = lodashGet(fileTypeResult, 'ext');
            let options = {
                ...CONSTANTS.POST_DEFAULT_OPTIONS,
                body: JSON.stringify({
                    recaptchaToken,
                    secretAgentKey: routerProps.id,
                    key: selectedPersonToView.key,
                    filesArray: [{ fileContent: modifiedUserFileObjects[fileType].fileContent, filename: 'default.' + fileTypeResultExtension, fileType }]
                })
            };
            let result = await (await fetch(url, options)).json();
            if (lodashGet(result, 'data.success')) {
                // update object

                if (lodashGet(apiResult, 'data.type') == 'group') {
                    let list = lodashGet(apiResult, 'data.linkedIndividualApplications');
                    let matchedIndividual = false;
                    if (Array.isArray(list)) {
                        for (let loop = 0 ; loop < list.length && !matchedIndividual ; ++loop) {
                            if (list[loop].key == selectedPerson.key) {
                                // match
                                matchedIndividual = true;
                                let matchedFile = false;
                                for (let fLoop = 0 ; fLoop < list[loop].files.length && !matchedFile ; ++fLoop) {
                                    if (list[loop].files[fLoop].fileType == fileType) {
                                        matchedFile = true;
                                        list[loop].files[fLoop].fileContent = modifiedUserFileObjects[fileType].fileContent;
                                        list[loop].files[fLoop].showAsPdf = getExtensionByFileContent(list[loop].files[fLoop].fileContent) == 'pdf';
                                    }
                                }
                                if (!matchedFile) {
                                    // add new
                                    let newItem = { fileContent: modifiedUserFileObjects[fileType].fileContent, fileType };
                                    if (getExtensionByFileContent(newItem.fileContent) == 'pdf') {
                                        newItem.showAsPdf = true;
                                    }
                                    list[loop].files.push(newItem);
                                }
                            }
                        }
                        setApiResult({ ...apiResult });
                    }

                }
                else {
                    // individual
                    let individualObject = lodashGet(apiResult, 'data.individualApplication');
                    let matchedFile = false;
                    for (let fLoop = 0 ; fLoop < individualObject.files.length && !matchedFile ; ++fLoop) {
                        if (individualObject.files[fLoop].fileType == fileType) {
                            matchedFile = true;
                            individualObject.files[fLoop].fileContent = modifiedUserFileObjects[fileType].fileContent;
                            individualObject.files[fLoop].showAsPdf = getExtensionByFileContent(individualObject.files[fLoop].fileContent) == 'pdf';
                        }
                    }
                    if (!matchedFile) {
                        let newItem = { fileContent: modifiedUserFileObjects[fileType].fileContent, fileType };
                        if (getExtensionByFileContent(newItem.fileContent) == 'pdf') {
                            newItem.showAsPdf = true;
                        }
                        individualObject.files.push(newItem);
                    }
                    setApiResult({ ...apiResult });
                }
                setModifiedUserFileObjects({ ...modifiedUserFileObjects, [fileType]: null });
            }
            setLoaders({ ...loaders, [loaderName]: false });
            setIsLoading(false);
        }
        catch (err) {
            setLoaders({ ...loaders, [loaderName]: false });
            setIsLoading(false);
        }
    }

    function isLoaderActive() {
        for (let loaderKey in loaders) {
            if (loaders[loaderKey]) {
                return true;
            }
        }
        return false;
    }

    function cropHandlerCallback(croppedImage) {
        setModifiedUserFileObjects({ ...modifiedUserFileObjects, [this.fileType]: (croppedImage !== false ? { fileContent: croppedImage, finished: true } : null) });
    }
}

const AgentManagerStyle = styled.div`
  text-align: center;
  .general-error-message {
    color: ${CSS_COLORS.FIELD_WARNING};
  }
  .MuiButtonBase-root.MuiButtonBase-root {
    text-transform: none;
  }
  .logo-image {
    max-height: 60px;
  }
  .checked-icon-container {
    display: inline-block;
    position: relative;
    top: 6px;
  }
  .main-table-component {
    position: relative;
    width: 100%;
  }
  .sticky-header {
    top: 0;
    //position: sticky;
    background-color: white;
    z-index: 2;
  }
  .group-short-instructions-inner-container {
    display: inline-block;
    text-align: left;
  }
  .start-procedure-button-container {
    margin-top: 15px;
    margin-bottom: 15px;
  }
  .error-message-container {
    font-weight: bold;
    color: ${CSS_COLORS.FIELD_WARNING};
  }
  .persons-buttons-column {
    padding-top: 10px;
    width: 250px;
  }
  .select-person-button {
    border: 1px solid black;
    margin-bottom: 10px;
    &.selected-person {
      background-color: lightgreen;
    }
  }
  .files-information-container {
    text-align: left;
    h2 {
      text-align: center;
    }
  }
  .table-main-h2 {
    background-color: rgba(0,0,255,0.1);
    margin: 0;
  }
  .file-item-container {
    display: inline-block;
    border: 1px dashed black;
    b {
      width: 100%;
      display: inline-block;
      border: 1px dashed black;
    }
  }
  .user-file-upload {
    display: none;
  }
  .client-pictures-component {
    max-width: 100%;
    &.passport {
      max-width: 500px;
    }
    &.passportFace {
      max-width: 350px;
    }
    &.personalIdWithAppendix {
      max-width: 500px;
    }
    &.healthGreenCardOrVaccination {
      max-width: 500px;
    }
    &.birthCertificate {
      max-width: 600px;
    }
    &.parentPassport {
      max-width: 500px;
    }
    &.passportBackside {
      max-width: 500px;
    }
  }
  .agent-action-button {
    border: 1px solid black;
  }
  .user-files-action-button {
    width: 100%;
    border: 1px solid black;
    &.final-upload-confirmation {
      font-weight: bold;
    }
  }
  .upload-error-message {
    max-width: 560px;
  }
  .warning-color {
    font-weight: bold;
    color: ${CSS_COLORS.FIELD_WARNING};
  }
  .stage-header {
    background-color: #f5f5f5;
    border: 1px solid #aaa;
    cursor: pointer;
    height: 60px;
  }
  .stage-content {
    border: 1px solid #aaa;
    border-top: none;
  }
  .stage-content-container {
    padding: 20px;
  }
  .fields-row-container {
    @media (min-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      margin-bottom: 20px;
      &.last {
        margin-bottom: 0;
      }
    }
  }
  .special-text-display {
    color: red;
  }
  .table-component {
    border: 1px solid #aaa;
  }
  .table-header-component {
    background-color: #ddd;
  }
  .align-left {
    text-align: left;
  }
  .citizen-of-any-other-country-question {
    margin-top: 20px;
    padding-bottom: 5px;
    text-align: left;
  }
  .citizen-of-other-country-table {
    td {
      word-break: break-word;
    }
  }
  .stage3-phones-container {
    margin-top: 20px;
    text-align: left;
  }
  .stage5-questions-container {
    text-align: left;
  }
  .disabled-notification {
    color: ${CSS_COLORS.FIELD_WARNING};
  }
  .upload-all-files-button {
    min-width: 150px;
    margin-top: 30px;
    margin-bottom: 10px;
    border: 1px solid black;
  }
  .upload-visa-dropzone-container {
    max-width: 80%;
    margin: 0 auto;
  }
  .upload-visa-dropzone-container.inactive {
    position: relative;
    z-index: -50;
    opacity: 0.4;
  }
  .upload-error-container {
    font-weight: bold;
    color: ${CSS_COLORS.FIELD_WARNING};
  }
  .finish-button, .reject-button {
    font-weight: bold;
    min-width: 150px;
    margin-top: 10px;
    margin-bottom: 10px;
    border: 1px solid black;
  }
  .finish-button {
    border: 1px solid green;
    color: green;
  }
  .reject-button {
    border: 1px solid ${CSS_COLORS.FIELD_WARNING};
    color: ${CSS_COLORS.FIELD_WARNING};
  }
  .reject-reason-error-text {
    color: ${CSS_COLORS.FIELD_WARNING};
  }
  .reject-reasons-container {
    display: inline-block;
    &.warning {
      border: 1px solid ${CSS_COLORS.FIELD_WARNING};
    }
  }
  .reject-reasons-table, .reject-reasons-table td {
    border: 1px solid;
  }
  .support-container {
    margin-top: 25px;
    margin-bottom: 25px;
  }
`;

const ModalComponentStyle = styled.div`
    outline: none;
    > div {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
    .positioning-container {
      width: 60%;
      position: relative;
      top: 30px;
      @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
        width: auto;
        top: 0;
      }
    }
    #modal-content {
      padding: 10px 10px;
      background-color: white;
    }
    #modal-title {
      height: 50px;
      background-color: lightgray;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    .close-button-container {
      text-align: center;
      > button {
        background-color: #fc3;
      }
    }
`;

const AgentManagerPageBody = connect(
    (state) => ({
    }),
    {})(AgentManagerPageBodyComponent);

export default AgentManagerPageBody;
