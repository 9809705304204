import React from 'react';
import styled from 'styled-components';
import {getContextRoot} from "../common/functions";

function Flag(props) {
    const FLAGS_POSITIONS = {
        en: [ -7328 ],
        he: [ -3648 ],
        hi: [ -3712 ]
    };
    return <FlagStyle className={props.flagKey} flagsPositions={FLAGS_POSITIONS[props.flagKey] || [0, 0]} />;
}

const FlagStyle = styled.span`
    display: inline-block;
    position: relative;
    width: 32px;
    height: 32px;
    vertical-align: middle;
    background-image: url(${getContextRoot() + 'images/flags.png'});
    background-position: 0 ${props => (props.flagsPositions[0])}px;
    background-repeat: no-repeat;
    html.rtl & {
      margin-left: 5px;
    }
    html.ltr & {
      margin-right: 5px;
    }
`;

export default Flag;
