import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {connect, useDispatch} from 'react-redux';
import Button from '@material-ui/core/Button';
import {changeFieldsWithPrefixAndCamelCase, getApiRoot, getContextRoot, getLanguageValue} from "../../common/functions";
import Grid from '@material-ui/core/Grid';
import lodashGet from 'lodash/get';
import RegisterBottomButtons from "./RegisterBottomButtons";
import GenericFieldComponent from '../GenericFieldComponent';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import RemoveCircleIcon from '@material-ui/icons/RemoveCircle';
import CONSTANTS from '../../common/constants';
import { CSS_COLORS } from '../../common/cssColors';
import { ACTIONS } from '../../redux/actions';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup';
import Radio from '@material-ui/core/Radio';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import ListItem from '@material-ui/core/ListItem';
import Collapse from '@material-ui/core/Collapse';
import moment from 'moment';
import EditIcon from '@material-ui/icons/Edit';
import SearchIcon from '@material-ui/icons/Search';
import DoneIcon from '@material-ui/icons/Done';

function RegisterIndividualSuccessComponent(props) {
    const dispatch = useDispatch();
    const {
        actions
    } = props;

    useEffect(() => {
        actions.setActiveStage(7);
    }, []);

    return <RegisterIndividualSuccessStyle>
        <h1>{getLanguageValue('register-individual.stageSuccess-completed-successfully-title')}</h1>
        <div className={"content-container"}>
            <DoneIcon className={"icon-component"} /><span className={"completed-successfully-subtext"}>{getLanguageValue(props.stage2Data.visaExpeditedFlag ? 'register-individual.stageSuccess-completed-successfully-expedite-subtext' : 'register-individual.stageSuccess-completed-successfully-subtext')}</span>
            <br/><br/>
            *<span>{getLanguageValue('register-individual.stageSuccess-your-form-number')}{props.individualKey.toUpperCase()}</span>
        </div>
    </RegisterIndividualSuccessStyle>;
}

const RegisterIndividualSuccessStyle = styled.div`
  width: 80%;
  margin: 0 auto;
  .content-container {
    margin-bottom: 25px;
  }
  .icon-component, .completed-successfully-subtext {
    vertical-align: middle;
  }
`;

const RegisterIndividualSuccess = connect(
    (state) => ({
        languageKey: state.languageKey, // make everything re-render
        individualKey: lodashGet(state, 'registerIndividualStagesData.key') || '',
        stage2Data: lodashGet(state, 'registerIndividualStagesData.stage2Data')
    }),
    {})(RegisterIndividualSuccessComponent);

export default RegisterIndividualSuccess;