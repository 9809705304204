import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import Header from './Header';
import TopMenu from './TopMenu';
import GlobalStyle from '../style/globalStyle';
import lodashGet from 'lodash/get';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {getContextRoot, getLanguageValue, getHelpTopics} from "../common/functions";
import CONSTANTS from '../common/constants';
import { makeStyles } from '@material-ui/core/styles';
import Fade from '@material-ui/core/Fade';
import { createGlobalStyle } from 'styled-components';
import HeaderWithLogoBackground from "./HeaderWithLogoBackground";
import MainFooter from "./MainFooter";
import FloatingWhatsApp from "react-floating-whatsapp";

function PrivacyPageBodyComponent(props) {
    return <>
    <GlobalStyle/>
    <MainPageBodyStyle>
        {CONSTANTS.WHATSAPP_ICON_ENABLED && <FloatingWhatsApp
            className={'floating-whatsapp'}
            phoneNumber={CONSTANTS.SUPPORT_PHONE_NUMBER}
            accountName={getLanguageValue('whatsapp.header-name')}
            statusMessage={getLanguageValue('whatsapp.status-message')}
            chatMessage={getLanguageValue('whatsapp.initial-message')}
        />}
        <Header className="header-component"/>
        <HeaderWithLogoBackground className="header-background-component" />
        <TopMenu className="top-menu-component" />
        <div className="content-body-container" dangerouslySetInnerHTML={{ __html: getLanguageValue('privacy-content-html')}} />
        <MainFooter className="footer-component" />
    </MainPageBodyStyle>
    </>;
}

const MainPageBodyStyle = styled.div`
  .content-body-container {
    padding: 25px;
  }
`;


const PrivacyPageBody = connect(
    (state) => ({
        languageKey: state.languageKey // make everything re-render
    }),
    {})(PrivacyPageBodyComponent);

export default PrivacyPageBody;