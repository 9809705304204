import React, {useEffect, useState} from 'react';
import styled from 'styled-components';
import {connect, useDispatch} from 'react-redux';
import {getApiRoot, getContextRoot, getLanguageValue} from "../../common/functions";
import Collapse from '@material-ui/core/Collapse';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import lodashGet from 'lodash/get';
import RegisterBottomButtons from "./RegisterBottomButtons";
import { CSS_COLORS } from '../../common/cssColors';
import CONSTANTS from '../../common/constants';
import RegisterStage1OcrSubProcess from "./RegisterStage1OcrSubProcess";

function RegisterStage1Component(props) {
    const dispatch = useDispatch();
    const contextRoot = getContextRoot();
    const {
        actions
    } = props;

    const [ disclaimersList, setDisclaimersList ] = useState(getDisclaimersList());
    const [ ocrSubProcessVisible, setOcrSubProcessVisible ] = useState(false);
    const registerSubProcessActions = {
        onClose: async function(reason) {
            setOcrSubProcessVisible(false);
            if (reason == 'abort') {
                // nothing to do
            }
            else if (reason == 'success' || reason == 'skip') {
                actions.nextStage();
            }
        }
    };

    useEffect(() => {
        actions.setActiveStage(1);
        window.routeChangeTimestamp = new Date().getTime();
    }, []);

    useEffect(() => {
        setDisclaimersList(getDisclaimersList());
    }, [ props.languageKey ]);

    return <RegisterStage1Style>
        <div className="disclaimers-container">
            <List>
                {disclaimersList.map(function(disclaimerItem, disclaimerItemIndex) {
                    return <React.Fragment key={disclaimerItemIndex}>
                        <ListItem className={"disclaimer-header" + (disclaimerItem.error ? ' field-error' : '')} onClick={() => { disclaimerItem.collapseState = !disclaimerItem.collapseState; setDisclaimersList([...disclaimersList]); }}>
                            { disclaimerItem.collapseState ? <ExpandMore /> : <ExpandLess />}
                            { disclaimerItem.title }
                            { disclaimerItem.error ? <> - {getLanguageValue('register-individual.stage1-disclaimer-please-confirm')}</> : null }
                        </ListItem>
                        <Collapse className={"disclaimer-content" + (disclaimerItem.error ? ' field-error' : '')} in={disclaimerItem.collapseState}>
                            <div className="disclaimer-text" dangerouslySetInnerHTML={{ __html: disclaimerItem.content }} />
                            <FormControl component="fieldset">
                                <RadioGroup aria-label="agreement" name="agreementRadios" value={disclaimerItem.answer} onChange={(e) => { disclaimerItem.error = false; disclaimerItem.answer = e.target.value; setDisclaimersList([...disclaimersList]); }}>
                                    <FormControlLabel
                                        classes={{ root: 'agree-label-control-root-component', label: 'agree-label-control-label-component' + (disclaimerItem.error ? ' field-error' : '') }}
                                        value="agree"
                                        control={<Radio
                                            classes={{ root: 'agree-radio-root' + (disclaimerItem.error ? ' field-error' : '') }}
                                            color="primary"
                                        />}
                                        label={getLanguageValue('register-individual.stage1-agree')}
                                    />
                                    <FormControlLabel
                                        classes={{ root: 'disagree-label-control-root-component', label: 'disagree-label-control-label-component' + (disclaimerItem.error ? ' field-error' : '') }}
                                        value="disagree"
                                        control={<Radio
                                            classes={{ root: 'disagree-radio-root' + (disclaimerItem.error ? ' field-error' : '') }}
                                            color="primary"
                                        />}
                                        label={getLanguageValue('register-individual.stage1-disagree')}
                                    />
                                </RadioGroup>
                            </FormControl>
                        </Collapse>
                        <br/>
                    </React.Fragment>;
                })}
            </List>
        </div>
        {ocrSubProcessVisible && <RegisterStage1OcrSubProcess actions={registerSubProcessActions} />}
        <RegisterBottomButtons
            actions={{
                isNextDisabled,
                next,
                exit
            }}
        />
    </RegisterStage1Style>;

    function getDisclaimersList() {
        const output = [];
        const disclaimersArray = (getLanguageValue('register-individual.stage1-disclaimers-array') || '').replace(/\s/g, '').split(',');
        for (let loop = 0 ; loop < disclaimersArray.length ; ++loop) {
            let itemValue = disclaimersArray[loop];
            let disclaimerItem = {
                key: itemValue,
                title: getLanguageValue('register-individual.stage1-' + itemValue + 'Title'),
                content: getLanguageValue('register-individual.stage1-' + itemValue + 'Content'),
                collapseState: true,
                answer: '',
                error: false
            };
            output.push(disclaimerItem);
        }
        return output;
    }

    function isNextDisabled() {
        return false;
    }

    async function next() {
        // goto next stage
        const validateResult = validate({ setErrors: true });
        if (validateResult.length) {
            setTimeout(function() {
                if (document.querySelectorAll('.field-error')[0]) {
                    document.querySelectorAll('.field-error')[0].scrollIntoView();
                }
            }, 50);
            return;
        }
        if (CONSTANTS.OCR_ENABLED) {
            setOcrSubProcessVisible(true);
            return;
        }
        if (actions && actions.nextStage) {
            actions.nextStage();
        }
    }

    function exit() {
        window.location.href = getContextRoot();
    }

    function validate(config) {
        const errors = [];
        for (let item of disclaimersList) {
            if (item.answer != 'agree') {
                errors.push('missing agree for key ' + item.key);
                if (lodashGet(config, 'setErrors')) {
                    item.error = true;
                }
            }
        }
        if (lodashGet(config, 'setErrors')) {
            setDisclaimersList([ ...disclaimersList ]);
        }
        return errors;
    }
}

const RegisterStage1Style = styled.div`
  padding: 20px 30%;
  @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
    padding: 20px 10%;
  }
  .disclaimer-header {
    background-color: #eee;
    border: 1px solid black;
    cursor: pointer;
    //margin-bottom: 10px;
    &.field-error {
      border: 1px solid ${CSS_COLORS.FIELD_WARNING};
    }
  }
  .disclaimer-content {
    border: 1px solid black;
    border-top: none;
  }
  .disclaimer-text {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 20px;
  }
  .agree-label-control-root-component, .disagree-label-control-root-component {
    html.ltr & {
      margin-left: 20px;
    }
  }
  .agree-label-control-label-component, .disagree-label-control-label-component, .agree-radio-root, .disagree-radio-root {
    &.field-error {
      color: ${CSS_COLORS.FIELD_WARNING};
    }
  }
`;

const RegisterStage1 = connect(
    (state) => ({
        languageKey: state.languageKey, // make everything re-render
        registerIndividualStagesData: state.registerIndividualStagesData,
        stage1Data: lodashGet(state, 'registerIndividualStagesData.stage1Data'),
        stage1DataDisclaimer: lodashGet(state, 'registerIndividualStagesData.stage1Data.disclaimer')
    }),
    {})(RegisterStage1Component);

export default RegisterStage1;