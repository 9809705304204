import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { connect, useDispatch } from 'react-redux';
import Header from './Header';
import TopMenu from './TopMenu';
import lodashGet from 'lodash/get';
import InfoIcon from '@material-ui/icons/Info';
import CloseIcon from '@material-ui/icons/Close';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import {getContextRoot, getLanguageValue, getHelpTopics} from "../common/functions";
import CONSTANTS from '../common/constants';
import ModalComponent from './Modal';
import { makeStyles } from '@material-ui/core/styles';
import { createGlobalStyle } from 'styled-components';
import useMediaQuery from '@material-ui/core/useMediaQuery';

function NeedHelpComponent(props) {
    const dispatch = useDispatch();
    const contextRoot = getContextRoot();
    const isMobile = useMediaQuery(`(max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px)`);
    const [ isModalOpened, setIsModalOpened] = useState(false);
    const [ selectedSubtopic, setSelectedSubtopic ] = useState({});
    const [ showMore, setShowMore ] = useState(true);
    const pageName = props.pageName;

    let helpTopics = rebuildHelpTopics(getHelpTopics());


    return <NeedHelpStyle id={'need-help-component'} className={props.className || ''}>
        <div className="background-container">
            <div className="frame-container">
                <Grid container direction={isMobile ? 'column' : 'row'} alignItems={"center"}>
                    <Grid item xs>
                        <h1 className="top-text-title"><span>{getLanguageValue('need-help-section.title')}</span></h1>
                        <h2 className="related-topics-text"><span>{getLanguageValue('need-help-section.related-topics')}</span></h2>
                        {
                            helpTopics.map(function(helpTopicItem, helpTopicItemIndex) {
                                if (helpTopicItem.subTopics.find((item) => (item['visibleOn' + pageName]))) {
                                    // will show this
                                    return <div key={helpTopicItemIndex}>
                                        <h2><span className="topic-title">{helpTopicItem.title}</span></h2>
                                        <ul className="subtopic-list-container">
                                            {helpTopicItem.subTopics.map(function(subtopicItem, subtopicItemIndex) {
                                                if (subtopicItem['visibleOn' + pageName]) {
                                                    return <li className={"subtopic-li-container"} key={subtopicItemIndex}>
                                                        <a className="subtopic-item" href="#" onClick={(e) => { e.preventDefault(); setSelectedSubtopic(subtopicItem); setIsModalOpened(true); } }>{subtopicItem.title}</a>
                                                    </li>;
                                                }
                                                return null;
                                            })}
                                        </ul>
                                    </div>;
                                }
                                return null;
                            })
                        }
                        {showMore && <Button className="show-more-button" onClick={() => { setShowMore(false); }}>{getLanguageValue('need-help-show-more-button')}</Button>}
                    </Grid>
                    <Grid className="mobile-separator">
                        <hr />
                    </Grid>
                    <Grid item xs className={"video-container" + (props.youtubeLink ? '' : ' hidden')}>
                        <div className={"youtube-container"}>
                            {props.youtubeLink ? <iframe id="ytplayer" type="text/html" src={props.youtubeLink} frameborder="0" allowfullscreen></iframe> : null}
                        </div>
                    </Grid>
                </Grid>
                <br/>
                <div className="ad-for-men-and-women">{getLanguageValue('need-help-section.for-men-and-women')}</div>
            </div>
        </div>
        <GlobalStyle />
        <ModalComponent
            useFade={true}
            modalClassName={'need-help-modal'}
            isOpen={isModalOpened}
            showTitleCloseButton={true}
            onClose={(src) => { setIsModalOpened(false) }}
            title={selectedSubtopic.title}
            content={<ModalComponentStyle>
                <ModalOverrideGlobalStyle />
                <div className={'html-container'}>{selectedSubtopic.fullHtml}</div>
                <div id="modal-buttons"><Button className="close-button" onClick={() => { setIsModalOpened(false); }}>{getLanguageValue('need-help-section.modal-close')}</Button></div>
            </ModalComponentStyle>}
        />
    </NeedHelpStyle>;

    function rebuildHelpTopics(helpTopicsData) {
        helpTopicsData = JSON.parse(JSON.stringify(helpTopicsData));
        if (showMore) {
            helpTopicsData = helpTopicsData.slice(0, 1);
            helpTopicsData[0].subTopics = helpTopicsData[0].subTopics.slice(0, 10);
        }

        return helpTopicsData;
    }
}

const ModalComponentStyle = styled.div`
  #modal-buttons {
    background-color: lightgray;
    text-align: center;
    padding: 7px;
  }
  .close-button {
    background-color: #fc3;
  }
  .modal-bottom-spacer {
    height: 20px;
  }
`;

const GlobalStyle = createGlobalStyle`
  #need-help-modal-component {
    overflow-y: auto;
  }
`;
const ModalOverrideGlobalStyle = createGlobalStyle`
  #modal-component.need-help-modal {
    #modal-title {
      font-size: 33px;
      @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
        font-size: 24px;
      }
    }
    #modal-content {
      padding: 0 !important;
      font-size: 22px;
    }
    .html-container {
      padding: 10px 10px;
    }
  }
`;


const NeedHelpStyle = styled.div`
  border-top: 5px solid #fc3;
  background-color: #eee;
  .background-container {
    background: url('${getContextRoot() + 'images/help_bg.jpg'}') no-repeat 100% 0;
    background-size: cover;
    width: 100%;
    min-height: 220px;
    display: flex;
    align-items: center;
    padding-top: 24px;
    padding-bottom: 24px;
  }
  .frame-container {
    width: 80%;
    margin: 0 auto;
    padding: 25px;
    background-color: rgba(187, 193, 199, 0.95);
    border-radius: 15px;
  }
  .top-text-title > span {
    font-size: 21px;
    background-color: #fc3;
  }
  .related-topics-text {
    margin-top: 15px;
    margin-bottom: 15px;
    font-weight: bold;
    font-size: 33px;
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      font-size: 24px;
    }
  }
  .topic-title {
    font-weight: bold;
    font-size: 22px;
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      font-size: 16px;
    }
  }
  .subtopic-list-container {
    html.rtl & {
      padding-right: 20px;
    }
    html.ltr & {
      padding-left: 20px;
    }
  }
  .subtopic-li-container {
    margin-bottom: 10px;
  }
  .subtopic-item, .subtopic-item:visited {
    text-decoration: none;
    color: #000;
    font-size: 22px;
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      font-size: 16px;
    }
  }
  .subtopic-item:hover {
    text-decoration: underline;
  }
  .show-more-button {
    font-size: 22px;
    color: white;
    background-color: #1c3764;
  }
  .mobile-separator {
    display: none;
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      display: block;
    }
  }
  .video-container {
    position: relative;
    padding-bottom: 30.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    &.hidden {
      display: none;
    }
    @media (max-width: ${CONSTANTS.MEDIA_BREAKPOINTS_MOBILE}px) {
      padding-bottom: 62.25%;
      width: 100%;
    }
  }
  .youtube-container {
    position: relative;
    padding-bottom: 56.25%;
    padding-top: 30px;
    height: 0;
    overflow: hidden;
    border-radius: 20px;
  }
  .youtube-container iframe,
  .video-container object,
  .video-container embed {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%;
  }
  .ad-for-men-and-women {
    font-size: 15px;
  }
  .view-all-topics-button {
    html.rtl & {
      margin-right: 15px;
    }
    html.ltr & {
      margin-left: 15px;
    }
    //margin-right: 15px;
    background-color: #fc3;
  }

`;

const NeedHelp = connect(
    (state) => ({
        youtubeLink: CONSTANTS.YOUTUBE_LINK,
        languageKey: state.languageKey // make everything re-render
    }),
    {})(NeedHelpComponent);

export default NeedHelp;
